import { render, staticRenderFns } from "./marker-display.vue?vue&type=template&id=4c12fa84&"
import script from "./marker-display.vue?vue&type=script&lang=js&"
export * from "./marker-display.vue?vue&type=script&lang=js&"
import style0 from "./marker-display.vue?vue&type=style&index=0&id=4c12fa84&prod&lang=less&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports