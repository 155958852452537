<template>
  <div :class="$style.wrap" id="groupTalkWrap">
    <div v-if="!current" :class="[$style.boxWrap, boxWrapStyle]">
      <div :class="$style.ctxBox" v-for="item in list" :key="item.deviceId">
        <div
          :class="$style.fullscreenWrap"
          @click.stop="handleFullscreen(item)"
        >
          <a-icon
            :class="$style.fullscreenIcon"
            :type="item.isFullScreen ? 'fullscreen-exit' : 'fullscreen'"
          />
        </div>
        <div :class="$style.audioAvatar">
          <x-oss-image
            :class="$style.avatar"
            :ossPath="item.avatar"
            v-if="item.avatar && item.videoType === 'audio'"
          />
          <img
            :class="$style.avatar"
            src="@/assets/img/default-avatar.png"
            v-if="!item.avatar && item.videoType === 'audio'"
          />
          <div
            :class="$style.loadText"
            v-if="item.videoType === 'audio' || !item.isCall"
          >
            {{
              item.isCall
                ? '通话中'
                : `网络${
                    item.videoType === 'audio' ? '电话' : '视频'
                  }正在接通中`
            }}
            <div v-if="!item.isCall" :class="$style.spinner">
              <div :class="$style.bounce1"></div>
              <div :class="$style.bounce2"></div>
              <div :class="$style.bounce3"></div>
            </div>
          </div>
        </div>
        <div
          :id="item.deviceUserId"
          :class="[$style.videoWrap, item.screenMode === 1 ? $style.vh : '']"
        >
          <div
            :class="$style.map"
            :style="`transform: translateY(${showMap ? '-260px' : '260px'});`"
          >
            <position-map :deviceId="item.deviceId" />
          </div>
        </div>
        <div :class="$style.avatarBox">
          <img
            :class="$style.avatar"
            src="@/assets/img/default-avatar.png"
            v-if="!item.avatar"
          />
          <x-oss-image v-else :class="$style.avatar" :ossPath="item.avatar" />
          <span :class="$style.text">{{ item.userName }}</span>
          <x-icon
            :class="$style.aIcon"
            type="tc-icon-address"
            style="
              font-size: 20px;
              color: #fff;
              margin-left: 10px;
              margin-right: 10px;
            "
            :style="`color: ${showMap ? '#fff' : 'gray'};`"
            @click="openPositionMap"
            v-if="list.length === 1"
          />
        </div>
        <ul :class="[$style.iconWrap, $style.activeWrap]">
          <li :class="$style.icon">
            <span
              :class="[$style.iconBg, $style.iconBgRed]"
              @click="handleUserLeave(item.deviceUserId)"
            >
              <a-icon :class="$style.aIcon" type="close" />
            </span>
            <span :class="$style.iconTxt">挂断</span>
          </li>
          <li :class="$style.icon">
            <span
              :class="[$style.iconBg, item.muted ? '' : $style.iconBgActive]"
              @click.stop="handleSound(item)"
            >
              <div :class="$style.line" v-if="item.muted"></div>
              <a-icon :class="$style.aIcon" type="sound" />
            </span>
            <span :class="$style.iconTxt">扬声器</span>
          </li>
          <li :class="$style.icon" v-if="item.videoType === 'video'">
            <span
              :class="$style.iconBg"
              @click.stop="handleCapturePicture(`#${item.deviceUserId} video`)"
            >
              <a-icon :class="$style.aIcon" type="scissor" />
            </span>
            <span :class="$style.iconTxt">截图</span>
          </li>
          <li :class="$style.icon" v-if="item.userType === 'DEVICE'">
            <span :class="$style.iconBg" @click.stop="handleChangeType(item)">
              <a-icon :class="$style.aIcon" type="retweet" />
            </span>
            <span :class="$style.iconTxt">{{
              item.videoType === 'video' ? '转为语音' : '转为视频'
            }}</span>
          </li>
          <li :class="$style.icon" v-if="item.videoType === 'video'">
            <span
              :class="[$style.iconBg, $style.setting]"
              @click.stop="handleChangeFPS(item)"
            >
              <x-icon
                :type="
                  item.screenMode
                    ? 'tc-icon-landscape-screen'
                    : 'tc-icon-portrait-screen'
                "
                :class="$style.screenIcon"
              />
              <span :class="$style.text">{{ item.resolution }}p</span>
            </span>
            <span :class="$style.iconTxt">画面</span>
          </li>
        </ul>
      </div>
    </div>
    <div v-else :class="[$style.boxMain]">
      <div :class="$style.main">
        <div :class="$style.fullscreenWrap">
          <a-icon
            :class="$style.fullscreenIcon"
            :type="main.isFullScreen ? 'fullscreen-exit' : 'fullscreen'"
            @click.stop="handleFullscreenExit(main)"
          />
        </div>
        <div
          :id="'main_' + main.deviceUserId"
          :class="[$style.videoWrap, main.screenMode === 1 ? $style.vh : '']"
        >
          <div
            :class="$style.map"
            :style="`transform: translateY(${showMap ? '-260px' : '260px'});`"
          >
            <position-map :deviceId="main.deviceId" />
          </div>
        </div>
        <div :class="$style.avatarBox">
          <img
            :class="$style.avatar"
            src="@/assets/img/default-avatar.png"
            v-if="!main.avatar"
          />
          <x-oss-image v-else :class="$style.avatar" :ossPath="main.avatar" />
          <span :class="$style.text">{{ main.userName }}</span>
          <x-icon
            :class="$style.aIcon"
            type="tc-icon-address"
            @click="openPositionMap"
            :style="`color: ${showMap ? '#fff' : 'gray'};`"
          />
        </div>
        <ul :class="[$style.iconWrap, $style.activeWrap]">
          <li :class="$style.icon">
            <span
              :class="[$style.iconBg, $style.iconBgRed]"
              @click.stop="handleUserLeave(main.deviceUserId)"
            >
              <a-icon :class="$style.aIcon" type="close" />
            </span>
            <span :class="$style.iconTxt">挂断</span>
          </li>
          <!-- <li :class="$style.icon">
            <span
              :class="[$style.iconBg, mic ? $style.iconBgActive : '']"
              @click.stop="handleMic"
            >
              <div :class="$style.line" v-if="!mic"></div>
              <a-icon :class="$style.aIcon" type="audio" />
            </span>
            <span :class="$style.iconTxt">麦克风</span>
          </li> -->
          <li :class="$style.icon">
            <span
              :class="[$style.iconBg, main.muted ? '' : $style.iconBgActive]"
              @click.stop="handleSound(main)"
            >
              <div :class="$style.line" v-if="main.muted"></div>
              <a-icon :class="$style.aIcon" type="sound" />
            </span>
            <span :class="$style.iconTxt">扬声器</span>
          </li>
          <li :class="$style.icon" v-if="videoType === 'video'">
            <span
              :class="$style.iconBg"
              @click.stop="
                handleCapturePicture(`main_${main.deviceUserId} video`)
              "
            >
              <a-icon :class="$style.aIcon" type="scissor" />
            </span>
            <span :class="$style.iconTxt">截图</span>
          </li>
          <li :class="$style.icon" v-if="main.userType === 'DEVICE'">
            <span :class="$style.iconBg" @click.stop="handleChangeType">
              <a-icon :class="$style.aIcon" type="retweet" />
            </span>
            <span :class="$style.iconTxt">{{
              videoType === 'video' ? '转为语音' : '转为视频'
            }}</span>
          </li>
          <li :class="$style.icon" v-if="main.videoType === 'video'">
            <span
              :class="[$style.iconBg, $style.setting]"
              @click.stop="handleChangeFPS(main)"
            >
              <x-icon
                :type="
                  main.screenMode
                    ? 'tc-icon-landscape-screen'
                    : 'tc-icon-portrait-screen'
                "
                :class="$style.screenIcon"
              />
              <span :class="$style.text">{{ main.resolution }}p</span>
            </span>
            <span :class="$style.iconTxt">画面</span>
          </li>
        </ul>
      </div>
      <div :class="$style.subMain">
        <span :class="$style.icon" @click="handlePrev">
          <a-icon type="up-circle" />
        </span>
        <div
          :class="[
            $style.subBox,
            main.deviceId === item.deviceId ? $style.active : '',
          ]"
          v-for="item in currentList"
          :key="item.deviceId"
          @click="handleChangeMain(item)"
        >
          <div :class="$style.avatarBox">
            <img
              :class="$style.avatar"
              src="@/assets/img/default-avatar.png"
              v-if="!item.avatar"
            />
            <x-oss-image v-else :class="$style.avatar" :ossPath="item.avatar" />
            <span :class="$style.text">{{ item.userName }}</span>
          </div>
          <div :class="$style.enumMsg" v-if="item.enumMsg">
            <span>{{ item.enumMsg }}</span>
            <div :class="$style.spinner">
              <div :class="$style.bounce1"></div>
              <div :class="$style.bounce2"></div>
              <div :class="$style.bounce3"></div>
            </div>
          </div>
          <div
            :id="item.deviceUserId"
            :class="[$style.videoWrap, item.screenMode === 1 ? $style.vh : '']"
          ></div>
        </div>
        <span :class="$style.icon" @click="handleNext">
          <a-icon type="down-circle" />
        </span>
      </div>
    </div>
    <div :class="$style.personCtl">
      <span
        :class="$style.personTip"
        v-if="videoMode === VIDEO_MODE.SENSELESS_MONITORING"
        >巡查模式（无提醒）</span
      >
      <span
        :class="$style.personTip"
        v-if="videoMode === VIDEO_MODE.SENSORY_MONITORING"
        >巡查模式（有限提醒）</span
      >
      <ul :class="$style.iconWrap">
        <li :class="$style.icon">
          <span
            :class="[$style.iconBg, $style.iconBgRed]"
            @click.stop="handleHandUp"
          >
            <a-icon :class="$style.aIcon" type="close" />
          </span>
          <span :class="$style.iconTxt">挂断</span>
        </li>
        <li :class="$style.icon">
          <span
            :class="[$style.iconBg, localMic ? $style.iconBgActive : '']"
            @click.stop="handleLocalMic"
          >
            <div :class="$style.line" v-if="!localMic"></div>
            <a-icon :class="$style.aIcon" type="audio" />
          </span>
          <span :class="$style.iconTxt">麦克风</span>
        </li>
        <!-- <li :class="$style.icon">
          <span
            :class="$style.iconBg"
            @click.stop="handleCapturePicture('#groupTalkWrap')"
          >
            <a-icon :class="$style.aIcon" type="scissor" />
          </span>
          <span :class="$style.iconTxt">截图</span>
        </li> -->
        <li :class="$style.icon">
          <span
            :class="[$style.iconBg, $style.setting]"
            @click.stop="handleAddDevice"
          >
            <a-icon type="user-add" :class="$style.screenIcon" />
          </span>
          <span :class="$style.iconTxt">添加</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { message } from 'ant-design-vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import RtcEngine from 'dingrtc';
import { globalSocket } from '@triascloud/message-hub';
import { HatSocket } from '@/enum/socket';
import { crossStorageModule } from '@/enum/store.js';
import {
  updateDeviceData,
  createRoomByMonitor,
  createRoomByNormal,
  closeRoom,
  leaveChannel,
  queryDevice,
  inviteByNormal,
  inviteByMonitor,
  agree,
  getDeviceList,
} from '@/services/smart-hat/device-management';
import { createFormModal } from '@triascloud/x-components';
import { capturePicture } from '../../utils';
import { delay } from '@triascloud/utils';
import ScreenSetting from '@/views/hat/device/manage-model/person-info/screen-setting.vue';
import AddDevice from './add-device.vue';
import { getListByIds } from '@/services/tenant-center/tenant';
import { Debounce } from 'lodash-decorators';
import PositionMap from '@/views/hat/device/components/position-map.vue';

/** @name 通话Event枚举 */
const TALK_ENUM = {
  NOT_CONVENIENT: '用户不方便接听，请稍后再拨！',
  USER_BUSY: '用户忙，请稍后再拨！',
  WRONG_INFORMATION: '频道订阅验证信息错误！',
  ON_THE_LINE: '用户通话中，请稍后再试！',
  NETWORK_ERROR: '设备网络环境差，无法正常通话！',
  LEAVE: '用户已离开房间！',
  // WAITING: '等待用户接听中，请稍后！',
};
/** @name 获取DEVICE_orUSER_用户列表 */
const findDevice = (arr = [], type = 'DEVICE_') => {
  return arr.filter(x => x.id.includes(type));
};

/** @name 视频通话的模式：1普通；2无感巡查；3有感巡查 */
const VIDEO_MODE = {
  /** @name 正常音视频通话 */
  NORMAL: 'NORMAL',
  /** @name 无感巡查 */
  SENSELESS_MONITORING: 'SENSELESS_MONITORING',
  /** @name 有感巡查 */
  SENSORY_MONITORING: 'SENSORY_MONITORING',
};

/** @name 普通布局展示最高个数 */
const MAX_TOTAL = 12;

/** @name 设备短名 */
const shortNameFn = name => {
  try {
    if (name.includes('DEVICE_')) {
      name = 'DEVICE_' + name.split('DEVICE_')[1].slice(-4);
      return name;
    }
  } catch (error) {
    return name;
  }
};

@Component({
  components: { PositionMap },
})
export default class GroupTalk extends Vue {
  @Prop({ type: Function }) close;
  @Prop({
    type: Array,
    default: [],
  })
  arr;

  VIDEO_MODE = VIDEO_MODE;

  async mounted() {
    this.mountSocket();
    this.list = this.arr;
    this.styleBox();
    if (this.beInvitedChannelId) {
      this.channelId = this.beInvitedChannelId;
      await this.inviteUser([this.user.pkId], []);
    } else {
      await this.createRoom();
    }
  }
  beforeDestroy() {
    this.destroyFn();
    this.destroySocket();
  }
  destroyFn() {
    if (this.RTCClient) {
      if (this.localAudioTrack) {
        this.RTCClient.unpublish([this.localAudioTrack]);
        this.localAudioTrack.close();
        this.localAudioTrack = null;
      }
      this.RTCClient.removeAllListeners();
    }
  }

  async handleAddDevice() {
    const deviceIds = this.list.map(v => v.deviceId);
    const result = await createFormModal(
      () => <AddDevice deviceIds={deviceIds} />,
      {
        width: 530,
        title: '添加成员',
        okText: '添加',
      },
    );
    if (result && result.length > 0) {
      const deviceIds = result.map(v => v.deviceId);
      await this.inviteUser([], deviceIds);
      this.list.push(...result);
    }
  }

  /** @name 切换音视频 */
  async handleChangeType(item) {
    if (item.videoType === 'video') {
      item.videoType = 'audio';
    } else {
      item.videoType = 'video';
    }
    // 通过接口去控制音视频切换
    const postData = {
      deviceId: item.deviceId,
      exData: {
        audioStream: true,
        videoStream: item.videoType === 'video',
      },
      type: 'MEDIA_SWITCHING',
    };
    await updateDeviceData(postData);
    await delay(500);
    if (item.videoType === 'audio') {
      item.remoteVideoTrack.stop();
    } else {
      if (item.remoteVideoTrack) {
        item.remoteVideoTrack.play(`#${item.deviceUserId}`, {
          // fit: 'cover',
        });
        this.responsiveVideo(`#${item.deviceUserId}`);
      } else {
        this.subscribeVideo([
          {
            id: item.deviceUserId,
            element: `#${item.deviceUserId}`,
          },
        ]);
      }
    }
  }
  /** @name 更换分辨率 */
  async handleChangeFPS(item) {
    try {
      const result = await createFormModal(
        () => (
          <ScreenSetting
            deviceId={item.deviceId}
            resolution={item.resolution}
            screenMode={item.screenMode}
          />
        ),
        {
          width: '400px',
          maskClosable: false,
          title: '画面设置',
        },
      );
      if (result) {
        await updateDeviceData(result);
        message.success('设置成功！');
        item.resolution = result.exData.resolution;
        item.screenMode = result.exData.screenMode;
      }
    } catch {
      return false;
    }
  }

  async inviteUser(users = [], devices = []) {
    const USER = users;
    const DEVICE = devices;
    const request =
      this.videoMode === VIDEO_MODE.NORMAL ? inviteByNormal : inviteByMonitor;
    const result = await request({
      channelId: this.channelId,
      recipients: {
        USER,
        DEVICE,
      },
    });
    if (result) {
      await agree(`channelId=${this.channelId}`);
    }
  }

  /** @name 被邀请的频道Id */
  @Prop({ type: String, default: '' }) beInvitedChannelId;
  @Prop({ type: String, default: 'video' }) videoType;
  @Prop({
    type: String,
    default: VIDEO_MODE.SENSELESS_MONITORING,
  })
  videoMode;
  @crossStorageModule.State user;
  channelId = '';
  async createRoom() {
    const request =
      this.videoMode === VIDEO_MODE.NORMAL
        ? createRoomByNormal
        : createRoomByMonitor;
    const deviceIds = this.list.map(v => v.deviceId);
    const { channelId } = await request({
      audioStream: true,
      videoStream: true,
      initiator: {
        channelType: 'USER',
        initiatorId: this.user.pkId,
        tenantId: this.user.tenant.tenantId,
      },
      recipients: {
        DEVICE: deviceIds,
      },
      resolution: 'RESOLUTION_720',
      mode: this.videoMode,
    });
    this.channelId = channelId;
  }
  mountSocket() {
    globalSocket.on(HatSocket.DeviceCall, this.joinSocket);
    globalSocket.on(HatSocket.RecipientInfo, this.eventSocket);
  }
  destroySocket() {
    globalSocket.off(HatSocket.DeviceCall, this.joinSocket);
    globalSocket.off(HatSocket.RecipientInfo, this.eventSocket);
  }
  joinSocket(data) {
    if (data.data.appId) {
      this.initRTC(data.data);
    }
  }
  eventSocket(data) {
    const devices = Object.values(data.data);
    const userIds = devices.map(v => {
      return {
        id: v.userId,
        element: `#${v.userId}`, // TODO 需修改
      };
    });
    this.userList = userIds;
    if (devices.length > 0) {
      const filterDeviceList = devices.filter(v => v.userId.includes('DEVICE'));
      filterDeviceList.forEach(async device => {
        const fItem = this.list.find(v => v.deviceUserId === device.userId);
        if (fItem) {
          fItem.status = device.userStatus;
          if (!fItem.userName) {
            fItem.userName = device.userName;
          }
          if (device.userStatus !== 'WAITING') {
            // this.closeVoice();
          }
          if (device.userStatus === 'SUCCESS') {
            fItem.isCall = true;
          }
          const enumMsg = TALK_ENUM[device.userStatus];
          if (enumMsg) {
            fItem.isCall = false;
            fItem.enumMsg = enumMsg;
            // 用户离开的提示，已经由user-left处理了
            if (fItem.userStatus !== 'LEAVE') {
              message.error(enumMsg);
            }
            this.handleUserLeave(fItem.deviceUserId);
          }
        }
      });
    } else {
      this.RTCClient.leave();
      this.close();
    }
  }
  /**
   * @name 退出频道和关闭房间
   * @description 挂断分为两种情况，单设备通话，和群组通话
   */
  async handleHandUp() {
    this.RTCClient.leave();
    if (this.list.length === 0) {
      await closeRoom(this.channelId);
      return;
    }
    const filterDevices = this.list.filter(v =>
      v.deviceUserId.includes('DEVICE_'),
    );
    if (filterDevices.length === 0) {
      await closeRoom(this.channelId);
      return;
    }
    const data = await queryDevice(this.list[0].deviceUserId);
    if (data) {
      const { recipients } = data;
      const array = Object.values(recipients);
      const filters = array.filter(
        v => v.userStatus === 'SUCCESS' && v.userId.includes('USER_'),
      );
      if (filters.length === 1) {
        await closeRoom(this.channelId);
      }
    } else {
      await closeRoom(this.channelId);
    }
    this.close();
  }
  /** @name 踢用户出房间 */
  async handleUserLeave(deviceUserId) {
    const query = `channelId=${this.channelId}&userId=${deviceUserId}`;
    await leaveChannel(query);
    const fIndex = this.list.findIndex(v => v.deviceUserId === deviceUserId);
    if (fIndex !== -1) {
      // 踢出的用户是主次屏的main用户
      if (this.main.deviceUserId === deviceUserId) {
        if (this.list[fIndex - 1]) {
          this.handleChangeMain(this.list[fIndex - 1]);
        } else if (this.list[fIndex + 1]) {
          this.handleChangeMain(this.list[fIndex + 1]);
        }
      }
      this.list.splice(fIndex, 1);
      this.afterResponsiveVideo();
    }
    const filters = this.list.filter(v => v.deviceUserId.includes('DEVICE_'));
    if (filters.length === 0) {
      await closeRoom(this.channelId);
      this.close();
    }
  }
  /**
   * @type { import('dingrtc').DingRTCClient }
   */
  RTCClient = null;
  /** @name rtc初始化 */
  async initRTC(info) {
    try {
      this.RTCClient = RtcEngine.createClient();
      const supported = RtcEngine.checkSystemRequirements();
      if (supported) {
        this.onPublisher();
        this.onUserJoined();
        this.onUserLeft();
        await this.joinRoom(info);
      }
    } catch (error) {
      this.$message.error(error);
    }
  }
  onUserJoined() {
    this.RTCClient.on('user-joined', user => {
      const fUser = this.list.find(v => v.deviceUserId === user.userId);
      let userName = '';
      if (fUser) {
        userName = fUser.userName;
      } else {
        userName = user.userName ? user.userName : user.userId;
        userName = shortNameFn(userName);
      }
      this.$message.info(`【${userName}】加入房间`);
    });
  }
  /**
   * 需处理，设备端挂断后，用户界面还在通话中
   */
  onUserLeft() {
    this.RTCClient.on('user-left', async user => {
      let userName = user.userName ? user.userName : user.user;
      userName = shortNameFn(userName);
      this.$message.warning(`【${userName}】离开房间`);
      const filterDevices = this.list.filter(v =>
        v.deviceUserId.includes('DEVICE_'),
      );
      if (filterDevices.length === 0) {
        await closeRoom(this.channelId);
        this.close();
        return;
      }
      const data = await queryDevice(this.list[0].deviceUserId);
      if (data) {
        const { recipients } = data;
        const array = Object.values(recipients);
        const filters = array.filter(
          v => v.userStatus === 'SUCCESS' && v.userId.includes('USER_'),
        );
        const filterDevices = array.filter(
          v => v.userStatus === 'SUCCESS' && v.userId.includes('DEVICE_'),
        );
        if (filters.length === 1 && filterDevices.length === 0) {
          await closeRoom(this.channelId);
          this.close();
          return;
        }
      } else {
        await closeRoom(this.channelId);
        this.close();
        return;
      }
    });
  }
  onPublisher() {
    this.RTCClient.on('user-published', async (user, mediaType) => {
      window.console.log('onPublisher', user, mediaType);
      await this.addUser(user);
      // console.log(this.list);
      const fItem = this.list.find(v => v.deviceUserId === user.userId);
      if (fItem) {
        // 当前是视频播放，才订阅视频流
        if (user && mediaType === 'video' && fItem.videoType === 'video') {
          await this.subscribeVideo([
            {
              id: user.userId,
              element: `#${user.userId}`,
            },
          ]);
        }
      }
      if (user && mediaType === 'audio') {
        await this.subscribeAudio([
          {
            id: user.userId,
            element: `#${user.userId}`,
          },
        ]);
      }
    });
  }
  muted = false;
  localMic = false;
  /** @name 本地音频控制 */
  async handleLocalMic() {
    if (!this.localAudioTrack) return;
    if (this.localAudioTrack.isPlaying) {
      this.localAudioTrack.stop();
      await delay(500);
      await this.localAudioTrack.setMuted(false);
    } else {
      await this.localAudioTrack.setMuted(true);
      await delay(500);
      this.localAudioTrack.play();
    }
    this.localMic = !this.localMic;
  }
  /** @type {import('dingrtc').MicrophoneAudioTrack} @name 本地音频 */
  localAudioTrack = null;
  @Prop({ type: Object }) detail;
  async joinRoom(info) {
    try {
      const joinInfo = {
        appId: info?.appId,
        uid: info?.userId,
        userName: this.user.memberName,
        token: info?.token,
        channel: info?.channelId,
      };
      const { remoteUsers } = await this.RTCClient.join(joinInfo);
      this.remoteUsersSubscribe(remoteUsers);
      let tracks = [];
      // 采集麦克风
      const micTrack = await RtcEngine.createMicrophoneAudioTrack();
      this.localAudioTrack = micTrack;
      tracks = [micTrack];
      if (this.videoMode !== VIDEO_MODE.NORMAL) {
        this.localAudioTrack.setMuted(false);
        this.localMic = false;
      } else {
        this.localAudioTrack.setMuted(true);
        this.localMic = true;
      }
      // 推流
      await this.RTCClient.publish(tracks);
    } catch (error) {
      window.console.error(error);
    }
  }
  /** @name 用户订阅音视频 */
  async remoteUsersSubscribe(remoteUsers) {
    if (remoteUsers && remoteUsers.length > 0) {
      // this.closeVoice();
      // this.isCall = true;
      // this.recordCallDuration();
      const deviceArray = remoteUsers
        .filter(v => v.userId.includes('DEVICE_'))
        .map(device => device.userId.split('DEVICE_')[1]);
      const deviceNames = deviceArray.join(',');
      const params = {
        current: 1,
        size: 50,
        deviceNames,
      };
      const { records } = await getDeviceList(params);
      const deviceList = records.map(v => {
        return {
          deviceUserId: 'DEVICE_' + v.deviceName,
          deviceId: v.deviceId,
          deviceName: v.deviceName,
          avatar: v.userAvatar,
          userName: v.userName,
          status: '',
          enumMsg: '',
          isCall: false,
          resolution: 720,
          screenMode: 0,
          videoType: 'video',
          muted: false,
          remoteVideoTrack: null,
          remoteAudioTrack: null,
          isFullScreen: false,
          lng: 0,
          lat: 0,
        };
      });
      let personList = [];
      if (this.videoMode === VIDEO_MODE.NORMAL) {
        const personArray = remoteUsers
          .filter(v => v.userId.includes('USER_'))
          .map(device => device.userId.split('USER_')[1]);
        const personData = await getListByIds(personArray);
        personList = personData.map(v => {
          return {
            deviceUserId: 'USER_' + v.pkId,
            deviceId: v.pkId,
            deviceName: v.pkId,
            avatar: v.avatar,
            userName: v.memberName,
            status: '',
            enumMsg: '',
            isCall: false,
            resolution: 720,
            screenMode: 0,
            videoType: 'audio',
            userType: 'USER',
            muted: false,
            remoteVideoTrack: null,
            remoteAudioTrack: null,
            isFullScreen: false,
            lng: 0,
            lat: 0,
          };
        });
      }
      let resultList = [];
      if (this.list.length === 0) {
        resultList = [...deviceList, ...personList];
      } else {
        const list = [...deviceList, ...personList];
        list.forEach(device => {
          const fItem = this.list.find(
            v => v.deviceUserId === device.deviceUserId,
          );
          if (!fItem) {
            resultList.push(device);
          }
        });
      }
      this.list.push(...resultList);
      if (this.list.length <= MAX_TOTAL) {
        const users = resultList.map(user => {
          return {
            id: user.deviceUserId,
            element: `#${user.deviceUserId}`,
          };
        });
        await Promise.all([
          this.subscribeVideo(users),
          this.subscribeAudio(users),
        ]);
      } else {
        this.styleBox();
      }
    }
  }
  /** @name 添加用户或设备 */
  @Debounce(500)
  async addUser(user) {
    const fItem = this.list.find(v => v.deviceUserId === user.userId);
    if (!fItem) {
      if (user.userId.includes('DEVICE_')) {
        const { records } = await getDeviceList({
          current: 1,
          size: 1,
          deviceNames: user.userId.split('DEVICE_')[1],
        });
        const deviceList = records.map(v => {
          return {
            deviceUserId: 'DEVICE_' + v.deviceName,
            deviceId: v.deviceId,
            deviceName: v.deviceName,
            avatar: v.userAvatar,
            userName: v.userName,
            status: '',
            enumMsg: '',
            isCall: false,
            resolution: 720,
            screenMode: 0,
            videoType: 'video',
            muted: false,
            remoteVideoTrack: null,
            remoteAudioTrack: null,
            isFullScreen: false,
            lng: 0,
            lat: 0,
          };
        });
        this.list.push(...deviceList);
      } else {
        if (this.videoMode === VIDEO_MODE.NORMAL) {
          const personData = await getListByIds([
            user.userId.split('USER_')[1],
          ]);
          const personList = personData.map(v => {
            return {
              deviceUserId: 'USER_' + v.pkId,
              deviceId: v.pkId,
              deviceName: v.pkId,
              avatar: v.avatar,
              userName: v.memberName,
              status: '',
              enumMsg: '',
              isCall: false,
              resolution: 720,
              screenMode: 0,
              videoType: 'audio',
              userType: 'USER',
              muted: false,
              remoteVideoTrack: null,
              remoteAudioTrack: null,
              isFullScreen: false,
              lng: 0,
              lat: 0,
            };
          });
          this.list.push(...personList);
        }
      }
    }
  }
  async subscribeVideo(users = []) {
    const arr = findDevice(users, 'DEVICE_');
    for (const user of arr) {
      const track = await this.RTCClient.subscribe(user.id, 'video');
      if (track && !track.isPlaying) {
        const fItem = this.list.find(v => v.deviceUserId === user.id);
        if (fItem) {
          fItem.remoteVideoTrack = track;
          fItem.isCall = true;
        }
        if (this.list.length > MAX_TOTAL) {
          this.styleBox();
          return false;
        }
        track.play(user.element);
        this.responsiveVideo(user.element);
      }
    }
    this.afterResponsiveVideo();
  }
  afterResponsiveVideo() {
    this.$nextTick(() => {
      if (!this.current) {
        if (this.list.length <= MAX_TOTAL) {
          this.list.forEach(device => {
            if (device.videoType === 'video' && device.remoteVideoTrack) {
              this.responsiveVideo(
                `#${device.deviceUserId}`,
                this.list.length === 4 || this.list.length === 2,
              );
            }
          });
          if (this.list.length === 1) {
            this.list.forEach(device => {
              if (device.videoType === 'video' && device.remoteVideoTrack) {
                this.responsiveVideo(`#${device.deviceUserId}`, true);
              }
            });
          }
        }
      } else {
        this.currentList.forEach(device => {
          if (
            device.videoType === 'video' &&
            device.remoteVideoTrack &&
            !device.enumMsg
          ) {
            this.responsiveVideo(`#${device.deviceUserId}`, true);
          }
        });
        this.responsiveVideo(`#main_${this.main.deviceUserId}`, true);
      }
    });
  }
  /**
   * @name 自适应Video元素的宽高
   * @param {boolean} isResponsive 是否自适应
   */
  responsiveVideo(element, isResponsive = false) {
    this.$nextTick(() => {
      try {
        const domWrap = document.querySelector(element);
        const domVideo = document.querySelector(`${element} video`);
        const wrapRect = domWrap.getBoundingClientRect();
        domVideo.removeAttribute('style');
        if (isResponsive) {
          domVideo.setAttribute(
            'style',
            `width: ${wrapRect.height}px !important;height: ${wrapRect.width}px !important`,
          );
        } else {
          domVideo.setAttribute('style', `height: 100% !important`);
        }
      } catch (error) {
        window.console.error(error);
      }
    });
  }
  async unsubscribeVideo(users = []) {
    const arr = findDevice(users, 'DEVICE_');
    for (const user of arr) {
      await this.RTCClient.unsubscribe(user.id, 'video');
    }
  }
  async subscribeAudio(users = []) {
    for (const user of users) {
      /** @type { import('dingrtc').RemoteAudioTrack ')} */
      const track = await this.RTCClient.subscribe('mcu', 'audio');
      if (track && !track.isPlaying) {
        const fItem = this.list.find(v => v.deviceUserId === user.id);
        if (fItem) {
          fItem.remoteAudioTrack = track;
          fItem.isCall = true;
        }
        track.setVolume(0.5);
        track.play();
      }
    }
  }
  /**
   * @name 远端声音开关控制
   */
  handleSound(item) {
    if (!item.remoteAudioTrack) {
      this.$message.warn('音频订阅不成功');
      this.subscribeAudio([
        {
          id: item.deviceUserId,
          element: `#${item.deviceUserId}`,
        },
      ]);
      return;
    }
    if (item.remoteAudioTrack.isPlaying) {
      item.remoteAudioTrack.stop();
    } else {
      item.remoteAudioTrack.play();
    }
    item.muted = !item.muted;
  }

  handleCapturePicture(item) {
    const video = document.querySelector(item);
    const rect = video.getBoundingClientRect();
    // TODO: 当摄像头可以调整角度后，这里的宽度和高度需要更改回来
    // capturePicture(video, rect.width, rect.height);
    capturePicture(video, rect.height, rect.width);
  }

  list = [];
  styleBox() {
    if (this.list.length > MAX_TOTAL) {
      this.handleFullscreen(this.list[0]);
    }
  }
  get boxWrapStyle() {
    if (this.list.length > MAX_TOTAL) {
      return '';
    } else {
      return this.$style[`box${this.list.length}`];
    }
  }
  current = '';
  limit = 0;
  currentList = [];
  async handleFullscreen(val) {
    // 布局为1，不需要切换
    if (this.list.length === 1) {
      return;
    }
    this.list.forEach(device => {
      if (device.remoteVideoTrack) {
        device.remoteVideoTrack.stop();
      }
    });
    val.isFullScreen = !val.isFullScreen;
    this.current = val.deviceId;
    this.main = val;
    if (this.list.length >= 5) {
      this.limit = +5;
      this.currentList = this.list.slice(0, 5);
    } else {
      this.currentList = this.list;
    }
    await delay(500);
    this.$nextTick(() => {
      if (this.main.videoType === 'video') {
        this.main.remoteVideoTrack.play(`#main_${this.main.deviceUserId}`, {
          // fit: 'cover',
        });
        this.responsiveVideo(`#main_${this.main.deviceUserId}`, true);
      }

      // TODO 需要currentList 音视频播放和 List 音视频停止
      this.currentList.forEach(async device => {
        if (
          device.remoteVideoTrack &&
          device.videoType === 'video' &&
          device.deviceUserId !== this.main.deviceUserId
        ) {
          device.remoteVideoTrack.play(`#${device.deviceUserId}`, {
            fit: 'cover',
          });
          this.responsiveVideo(`#${device.deviceUserId}`, true);
        }
        if (
          device.remoteVideoTrack &&
          device.videoType === 'video' &&
          device.deviceUserId === this.main.deviceUserId
        ) {
          device.enumMsg = '正在播放中';
        }
      });
    });
  }
  async handleFullscreenExit(val) {
    if (this.list.length > 12) {
      return;
    }
    this.currentList.forEach(device => {
      if (device.remoteVideoTrack) {
        device.remoteVideoTrack.stop();
      }
    });
    val.isFullScreen = !val.isFullScreen;
    this.current = '';
    this.$nextTick(() => {
      this.list.forEach(device => {
        if (device.remoteVideoTrack) {
          device.remoteVideoTrack.play(`#${device.deviceUserId}`, {
            // fit: 'cover',
          });
          this.responsiveVideo(
            `#${device.deviceUserId}`,
            this.list.length === 4 || this.list.length === 2,
          );
        }
      });
    });
    this.main = {};
    this.limit = 0;
    this.currentList = [];
  }
  main = {};
  async handleChangeMain(val) {
    let oldMain;
    if (this.main.remoteVideoTrack) {
      oldMain = this.main;
      this.main.remoteVideoTrack.stop();
    }
    // TODO 逻辑重写
    const fItem = this.list.find(v => v.deviceId === val.deviceId);
    if (fItem) {
      this.main = fItem;
      this.current = fItem.deviceId;
      if (fItem.remoteVideoTrack) {
        fItem.remoteVideoTrack.stop();
        fItem.enumMsg = '正在播放中';
      }
      if (oldMain.remoteVideoTrack) {
        oldMain.enumMsg = '';
        oldMain.remoteVideoTrack.play(`#${oldMain.deviceUserId}`);
        this.responsiveVideo(`#${oldMain.deviceUserId}`, true);
      }
      await delay(500);
      this.$nextTick(() => {
        if (this.main.remoteVideoTrack) {
          this.main.remoteVideoTrack.play(`#main_${this.main.deviceUserId}`, {
            // fit: 'cover',
          });
          this.responsiveVideo(`#main_${this.main.deviceUserId}`, true);
        }
      });
    }
  }
  handlePrev() {
    if (this.list.length > 0 && this.limit > 0 && this.limit !== 5) {
      this.limit -= 5;
      this.currentList = this.list.slice(this.limit - 5, this.limit);
      this.findMain();
    }
  }
  handleNext() {
    if (this.list.length > 0 && this.list.length > this.limit) {
      this.currentList = this.list.slice(this.limit, this.limit + 5);
      this.limit += 5;
      this.findMain();
    }
  }
  findMain() {
    // TODO 逻辑重写
    const fItem = this.currentList.find(v => v.deviceId === this.current);
    if (fItem) {
      this.main = fItem;
    }
  }

  showMap = false;
  openPositionMap() {
    this.showMap = !this.showMap;
  }
}
</script>
<style lang="less" module>
.wrap {
  border: 10px solid var(--border);
  box-sizing: content-box;
  overflow: hidden;
  height: calc(100% - 20px);
  .videoWrap {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    video {
      border-radius: 10px;
      transform: rotate(90deg);
      object-fit: contain !important;
    }
    .map {
      height: 260px;
      width: 400px;
      background-color: #fff;
      position: absolute;
      bottom: -260px;
      right: 0;
      z-index: 100;
      transition: all 0.3s;
    }
  }
  .vh {
    video {
      transform: rotate(0deg) !important;
    }
  }
}
.boxWrap {
  display: grid;
  grid-gap: 20px;
  padding: 20px;
  border-radius: 6px;
  background-color: var(--main-bg);
  .ctxBox {
    position: relative;
    background-color: var(--font);
    border-radius: 10px;

    .audioAvatar {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .avatar {
        width: 100px;
        height: 100px;
        border-radius: 100%;
      }
      .loadText {
        color: #fff;
        font-size: 14px;
        text-align: center;
        white-space: nowrap;
      }
    }
    .avatarBox {
      background-color: rgba(0, 0, 0, 0.5);
      height: 36px;
      border-radius: 95px;
      display: inline-flex;
      align-items: center;
      position: absolute;
      left: 20px;
      top: 10px;
      z-index: 1;
      white-space: nowrap;
    }
    .avatar {
      margin-left: 3px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    .text {
      font-size: 14px;
      margin: 0 4px;
      color: #fff;
    }
    &:hover {
      .activeWrap {
        display: flex;
        animation: all 2s ease-out;
      }
    }
  }
}
.boxMain {
  display: grid;
  grid-gap: 20px;
  border-radius: 10px;
  padding: 20px;
  background-color: var(--main-bg);
  grid-template-columns: 7fr 1fr;
  grid-template-rows: 1fr;
  .main {
    border-radius: 10px;
    background-color: var(--font);
    height: calc(100vh - 152px);
    position: relative;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
    &:hover {
      .activeWrap {
        display: flex;
        animation: all 2s ease-out;
      }
    }
    .avatarBox {
      background-color: rgba(0, 0, 0, 0.5);
      height: 36px;
      border-radius: 95px;
      display: inline-flex;
      align-items: center;
      position: absolute;
      left: 20px;
      top: 10px;
      z-index: 1;
      white-space: nowrap;
    }
    .avatar {
      margin-left: 3px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    .aIcon {
      font-size: 20px;
      color: #fff;
      margin-left: 10px;
      margin-right: 10px;
    }
    .text {
      font-size: 14px;
      margin-left: 4px;
      color: #fff;
    }
  }
  .subMain {
    border-radius: 10px;
    height: calc(100vh - 152px);
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
    .subBox {
      width: 100%;
      border-radius: 10px;
      height: 144px;
      box-sizing: border-box;
      background-color: var(--font);
      border: 2px solid transparent;
      position: relative;
    }
    .enumMsg {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 14px;
      display: flex;
    }
    .active {
      border: 2px solid var(--font-active);
    }
    .subBox + .subBox {
      margin-top: 10px;
    }
    .icon {
      height: 23px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .avatarBox {
      background-color: rgba(0, 0, 0, 0.5);
      height: 36px;
      border-radius: 95px;
      display: inline-flex;
      align-items: center;
      position: absolute;
      bottom: 6px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      white-space: nowrap;
    }
    .avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-left: 3px;
    }
    .text {
      font-size: 14px;
      color: #fff;
      margin: 0 4px;
    }
  }
}
.box1 {
  grid-template-columns: 1fr;
  .ctxBox {
    height: calc(100vh - 152px);
  }
}
.box2 {
  grid-template-columns: 1fr 1fr;
  .ctxBox {
    height: calc(100vh - 152px);
  }
}
.box3 {
  grid-template-columns: repeat(3, 1fr);
  .ctxBox {
    height: calc(100vh - 152px);
  }
}
.box4 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  .ctxBox {
    height: calc((100vh - 172px) / 2);
  }
}
.box5 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  .ctxBox {
    height: calc((100vh - 172px) / 2);
    &:nth-child(1) {
      grid-column: 1 / span 2;
      grid-row: 1;
    }
    &:nth-child(2) {
      grid-column: 3;
      grid-row: 1;
    }
    &:nth-child(3) {
      grid-column: 1;
      grid-row: 2;
    }
    &:nth-child(4) {
      grid-column: 2;
      grid-row: 2;
    }
    &:nth-child(5) {
      grid-column: 3;
      grid-row: 2;
    }
  }
}
.box6 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  .ctxBox {
    height: calc((100vh - 172px) / 2);
  }
}
.box7 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  .ctxBox {
    height: calc((100vh - 192px) / 3);
    &:nth-child(1) {
      grid-column: 1;
      grid-row: 1 / span 2;
      height: calc((100vh - 192px) / 3 * 2 + 20px);
    }
    &:nth-child(2) {
      grid-column: 2;
      grid-row: 1;
    }
    &:nth-child(3) {
      grid-column: 2;
      grid-row: 2;
    }
    &:nth-child(4) {
      grid-row: 1 / span 2;
      height: calc((100vh - 192px) / 3 * 2 + 20px);
    }
    &:nth-child(5) {
      grid-column: 1;
      grid-row: 3;
    }
    &:nth-child(6) {
      grid-column: 2;
      grid-row: 3;
    }
    &:nth-child(7) {
      grid-column: 3;
      grid-row: 3;
    }
  }
}
.box8 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  .ctxBox {
    height: calc((100vh - 192px) / 3);
    &:nth-child(1) {
      grid-column: 1;
      grid-row: 1 / span 2;
      height: calc((100vh - 192px) / 3 * 2 + 20px);
    }
    &:nth-child(2) {
      grid-column: 2;
      grid-row: 1;
    }
    &:nth-child(3) {
      grid-column: 3;
      grid-row: 1;
    }
    &:nth-child(4) {
      grid-column: 2;
      grid-row: 2;
    }
    &:nth-child(5) {
      grid-column: 3;
      grid-row: 2;
    }
    &:nth-child(6) {
      grid-column: 1;
      grid-row: 3;
    }
    &:nth-child(7) {
      grid-column: 2;
      grid-row: 3;
    }
    &:nth-child(8) {
      grid-column: 3;
      grid-row: 3;
    }
  }
}
.box9 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  .ctxBox {
    height: calc((100vh - 192px) / 3);
  }
}
.box10 {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  .ctxBox {
    height: calc((100vh - 192px) / 3);
    &:nth-child(1) {
      grid-column: 1;
      grid-row: 1 / span 2;
      height: calc((100vh - 192px) / 3 * 2 + 20px);
    }
    &:nth-child(2) {
      grid-column: 2;
      grid-row: 1 / span 2;
      height: calc((100vh - 192px) / 3 * 2 + 20px);
    }
    &:nth-child(3) {
      grid-column: 3;
      grid-row: 1;
    }
    &:nth-child(4) {
      grid-column: 4;
      grid-row: 1;
    }
    &:nth-child(5) {
      grid-column: 3;
      grid-row: 2;
    }
    &:nth-child(6) {
      grid-column: 4;
      grid-row: 2;
    }
    &:nth-child(7) {
      grid-column: 1;
      grid-row: 3;
    }
    &:nth-child(8) {
      grid-column: 2;
      grid-row: 3;
    }
    &:nth-child(9) {
      grid-column: 3;
      grid-row: 3;
    }
    &:nth-child(10) {
      grid-column: 4;
      grid-row: 3;
    }
  }
}
.box11 {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  .ctxBox {
    height: calc((100vh - 192px) / 3);
  }
  .ctxBox {
    height: calc((100vh - 192px) / 3);
    border-radius: 10px;
    &:nth-child(1) {
      grid-column: 1;
      grid-row: 1 / span 2;
      height: calc((100vh - 192px) / 3 * 2 + 20px);
    }
    &:nth-child(2) {
      grid-column: 2;
      grid-row: 1;
    }
    &:nth-child(3) {
      grid-column: 3;
      grid-row: 1;
    }
    &:nth-child(4) {
      grid-column: 4;
      grid-row: 1;
    }
    &:nth-child(5) {
      grid-column: 2;
      grid-row: 2;
    }
    &:nth-child(6) {
      grid-column: 3;
      grid-row: 2;
    }
    &:nth-child(7) {
      grid-column: 4;
      grid-row: 2;
    }
    &:nth-child(8) {
      grid-column: 1;
      grid-row: 3;
    }
    &:nth-child(9) {
      grid-column: 2;
      grid-row: 3;
    }
    &:nth-child(10) {
      grid-column: 3;
      grid-row: 3;
    }
    &:nth-child(11) {
      grid-column: 4;
      grid-row: 3;
    }
  }
}
.box12 {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  .ctxBox {
    height: calc((100vh - 192px) / 3);
  }
}
.fullscreenWrap {
  position: absolute;
  cursor: pointer;
  z-index: 10;
  top: 14px;
  right: 14px;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  .fullscreenIcon {
    font-size: 18px;
    color: #fff;
  }
}

.personCtl {
  height: 72px;
  margin: 0 20px 0 20px;
  // background-color: rgb(236, 219, 132);
  display: flex;
  justify-content: center;
  font-size: 14px;
  position: relative;
  .personTip {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    padding: 1px 10px;
    color: #fff;
    font-size: 12px;
    background-color: var(--font-active);
    border-radius: 4px;
  }
  .iconTxt {
    color: var(--font) !important;
  }
}
.iconWrap {
  display: flex;
  border-radius: 12px;
  .icon {
    padding: 4px 0;
    list-style: none;
    margin-right: 40px;
    text-align: center;
    cursor: pointer;
    .iconBg {
      display: inline-block;
      position: relative;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      &.iconBgRed {
        background-color: #f45454;
        .aIcon {
          font-weight: bolder;
          font-size: 24px;
        }
      }
      &.iconBgActive {
        // background-color: rgba(255, 255, 255) !important;
        .aIcon {
          color: #48ff00;
        }
      }
      .aIcon {
        font-size: 20px;
        color: #fff;
      }
      .text {
        color: #fff;
        font-size: 14px;
      }
      .line {
        height: 60%;
        width: 1px;
        position: absolute;
        border: 1px solid #fff;
        transform-origin: 50%;
        transform: rotateZ(45deg);
      }
    }
    .iconTxt {
      font-size: 12px;
      color: #fff;
      cursor: default;
    }
    .setting {
      display: flex;
      flex-direction: column;
      .text {
        color: #fff;
        font-size: 10px;
        margin-top: 2px;
      }
      .screenIcon {
        color: #fff;
        font-size: 16px;
      }
    }
  }
  .icon:last-of-type {
    margin-right: 0;
  }

  &.activeWrap {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 20px;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.4);
    height: 102px;
    .icon:first-of-type {
      border-right: 1px solid rgba(255, 255, 255, 0.6);
      padding-right: 20px;
      margin-right: 20px;
    }
  }
}

.spinner {
  width: 24px;
  text-align: center;
  display: inline-block;
}

.spinner > div {
  width: 8px;
  height: 8px;
  background-color: #ffffff;

  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
</style>
