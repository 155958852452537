<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { OssWpsService } from '@triascloud/services';
@Component()
export default class PreviewOffice extends Vue {
  @Prop({ type: String, required: true }) url;
  @Prop({ type: String, required: true }) name;
  @Prop({ type: String, required: true }) basePath;
  async created() {
    await this.getWpsUrl();
  }
  wpsUrl = '';
  loading = true;
  @Watch('url')
  async getWpsUrl() {
    this.loading = true;
    const services = new OssWpsService(this.basePath);
    await services
      .getViewUrlPath(this.url, this.name)
      .then(({ wpsUrl }) => (this.wpsUrl = wpsUrl));
    this.loading = false;
  }
  render() {
    if (this.loading) return <ASpin spinning={true} />;
    return <iframe src={this.wpsUrl} class="x-preview-iframe" />;
  }
}
</script>
