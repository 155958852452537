<template>
  <section
    :class="$style.content"
    :style="{
      width: $store.state.home.bigScreen ? '100vw' : '100%',
      height: $store.state.home.bigScreen ? '100vh' : '100%',
      position: $store.state.home.bigScreen ? 'fixed' : '',
      top: '-0',
      left: 0,
      zIndex: 9999,
    }"
  >
    <section :class="$style.timeWrap">
      <span
        >最近更新：<span :class="$style.time">{{
          formatDate(lastStatusTime)
        }}</span></span
      >
    </section>
    <div
      :class="$style.wrap"
      id="container"
      :style="{
        width: $store.state.home.bigScreen ? '100vw' : '100%',
        height: $store.state.home.bigScreen ? '100vh' : '100%',
      }"
    ></div>
    <section :class="$style.selectWrap">
      <span :class="$style.label">电子围栏</span>
      <a-select @change="handleChange" v-model="geofence">
        <a-select-option
          v-for="item in list"
          :key="item.geofenceId"
          :value="item.geofenceId"
          >{{ item.geofenceName }}</a-select-option
        >
      </a-select>
    </section>
    <div :class="$style.iconBtn">
      <div :class="$style.icon" @click="handleClose" title="关闭地图">
        <a-icon type="close" />
      </div>
    </div>
    <div :class="$style.positionValue" v-if="detail.status === 'ONLINE'">
      <div :class="$style.circleBox">
        <div :class="$style.circle">
          <div :class="$style.value">
            <span :class="$style.num">{{ speed }}</span>
            <span :class="$style.unit">km/h</span>
          </div>
          <div :class="$style.name">速度</div>
        </div>
        <div :class="$style.circle">
          <div :class="$style.value">
            <span :class="$style.num">{{ altitude }}</span>
            <span :class="$style.unit">m</span>
          </div>
          <div :class="$style.name">海拔</div>
        </div>
      </div>
      <div :class="$style.updateTime" @click="handleBinding">
        <a-icon type="history" :class="$style.timeIcon" />{{
          formatTime(updateTime)
        }}后更新
      </div>
    </div>
    <div :class="$style.toolBar">
      <div :class="$style.mapZoom">
        <div :class="$style.zoomBtn" title="放大" @click="setZoom('add')">
          <a-icon type="plus" />
        </div>
        <div :class="$style.zoomBtn" title="缩小" @click="setZoom('minus')">
          <a-icon type="minus" />
        </div>
      </div>
      <div :class="$style.toolBtn" title="地图缩放">
        <x-icon
          :type="
            $store.state.home.bigScreen
              ? 'tc-icon-fullscreen-exit'
              : 'tc-icon-fullscreen'
          "
          :class="$style.icon"
          @click="handleScreen"
        />
      </div>
      <div :class="$style.toolBtn" @click="measureDistance" title="测距">
        <i v-html="RuleIcon" :class="$style.icon"></i>
      </div>
      <div :class="$style.toolBtn" @click="refreshLocation" title="刷新定位">
        <i v-html="PositionIcon" :class="$style.icon"></i>
      </div>
      <div :class="$style.toolBtn" title="维度切换" @click="dimensionChange">
        <i v-html="twoDimensions" :class="$style.icon" v-if="!is3D"></i>
        <i v-html="threeDimensions" :class="$style.icon" v-else></i>
      </div>
    </div>
    <div :class="$style.mapType">
      <div
        @click="setMapType('1')"
        :class="[$style.typeBox, mapType === '1' ? $style.selected : '']"
      >
        <img
          src="@/assets/images/manage-model/satellite.png"
          alt="地球"
          srcset=""
        />
        <span :class="$style.title">地球</span>
      </div>
      <div
        @click="setMapType('2')"
        :class="[$style.typeBox, mapType === '2' ? $style.selected : '']"
      >
        <img
          src="@/assets/images/manage-model/administration.png"
          alt="行政"
          srcset=""
        />
        <span :class="$style.title">行政</span>
      </div>
    </div>
  </section>
</template>
<script>
import {
  Component,
  Inject,
  InjectReactive,
  Vue,
  Prop,
} from 'vue-property-decorator';
import {
  drawCharts,
  formatToApi,
  funAMap,
  mouseEvent,
} from '@/views/hat/electron-fence/components/util';
import { getFenceListById, getZonesData } from '@/services/smart-hat/geofence';
import { updateDevideInfo } from '@/services/smart-hat/device';
import { Debounce } from 'lodash-decorators';
import { createModuleUrl, Emitter } from '@/utils';
import { delay } from '@triascloud/utils';
import { createFormModal } from '@triascloud/x-components';
import PositionSetting from './position-setting.vue';
import RuleIcon from '!!svg-inline-loader!@/assets/svg/smart-hat/rule.svg';
import twoDimensions from '!!svg-inline-loader!@/assets/svg/smart-hat/2D.svg';
import threeDimensions from '!!svg-inline-loader!@/assets/svg/smart-hat/3D.svg';
import PositionIcon from '!!svg-inline-loader!@/assets/svg/smart-hat/position.svg';

@Component()
export default class MiniMap extends Vue {
  @Prop({ type: Array }) center;
  @Prop({ type: Object, default: () => {} }) record;

  RuleIcon = RuleIcon;
  twoDimensions = twoDimensions;
  threeDimensions = threeDimensions;
  PositionIcon = PositionIcon;
  lastStatusTime = 0;
  mounted() {
    this.initMap();
    this.getList();
    Emitter.$on('POSITION', this.positionFn);
    this.detail.status === 'ONLINE' &&
      this.getUpdateTime((this.detail.lastStatusTime / 1000).toFixed(0));
    this.lastStatusTime = this.detail.lastStatusTime;
  }
  speed = 0;
  altitude = 0;
  // 当socket定位更新时，执行此方法
  async positionFn(data) {
    const position = [data.lngLat.x, data.lngLat.y];
    this.speed = data.speed ? (data.speed * 3.6).toFixed(1) : data.speed;
    this.altitude = data.altitude;
    this.lastStatusTime = data.lastTime;
    this.getUpdateTime((data.lastTime / 1000).toFixed(0));
    if (position.length > 0) {
      // 设置点标记的动画效果，此处为弹跳效果
      this.centerMarker.moveTo(
        [this.center[0], Number(this.center[1]) + 0.0001],
        {
          duration: 500,
          delay: 100,
        },
      );
      await delay(1000);
      this.centerMarker.setPosition(position);
      this.positionRange.setCenter(position);
    }
  }

  updateTime = 0;
  updateTimer = null;
  getUpdateTime(lastUpdateTime) {
    const nowTime = (
      this.$moment(new Date().getTime()).valueOf() / 1000
    ).toFixed(0);
    const spaceTime = this.detail.bootPositionFrequency
      ? this.detail.bootPositionFrequency * 60
      : 10;
    this.updateTime = parseInt(lastUpdateTime) + spaceTime - parseInt(nowTime);
    this.updateTimer && clearInterval(this.updateTimer);
    this.updateTimer = null;
    this.updateTimer = setInterval(() => {
      if (this.updateTime > 0) {
        this.updateTime--;
      } else {
        this.updateTime = 0;
        clearInterval(this.updateTimer);
        this.updateTimer = null;
      }
    }, 1000);
  }

  beforeDestroy() {
    this.updateTimer && clearInterval(this.updateTimer);
    this.updateTimer = null;
    Emitter.$off('POSITION', this.positionFn);
  }

  formatDate(date) {
    return this.$moment(date).format('YYYY-MM-DD HH:mm:ss');
  }

  map = null;
  AMap = null;
  created() {
    this.$store.commit('home/setBigScreen', false);
    this.refreshLocation = this.refreshLocation.bind(this);
  }
  handleScreen() {
    const bigScreen = this.$store.state.home.bigScreen;
    this.$store.commit('home/setBigScreen', !bigScreen);
  }
  async initMap() {
    const AMap = await funAMap({
      plugins: ['AMap.MoveAnimation', 'AMap.RangingTool'],
    });
    this.AMap = AMap;
    const { mode } = this.$store.state.crossStorage.skin;
    this.map = new AMap.Map('container', {
      zoom: 16,
      viewMode: '3D',
      pitchEnable: true,
      pitch: 0,
      center: this.center || undefined,
      mapStyle: mode === 'dark' ? 'amap://styles/dark' : '',
    });
    const hatColor = {
      BLUE: '365',
      YELLOW: '366',
      RED: '367',
      WHITE: '368',
      ORANGE: '369',
    };
    let image;
    if (hatColor[this.record.color]) {
      image = `${createModuleUrl('iot-max')}/static/hat/${
        hatColor[this.record.color]
      }.png`;
    } else {
      image = `${createModuleUrl('iot-max')}/static/hat/365.png`;
    }
    const centerMarker = new this.AMap.Marker({
      position: this.center,
      icon: new this.AMap.Icon({
        imageSize: [36, 36],
        image,
      }),
      offset: [-18, -18],
    });
    this.centerMarker = centerMarker;
    this.addPositionRange();
    centerMarker.setMap(this.map);
  }

  /** 给定位加上区域范围 */
  positionRange = null;
  addPositionRange() {
    const POSITION_KEY = {
      BASE_STATION: 50,
      WIFI: 50,
      SATELLITE: 10,
    };
    this.positionRange = new this.AMap.Circle({
      center: this.center,
      radius: POSITION_KEY[this.record.positionType], // 半径
      borderWeight: 3,
      strokeColor: '#1791fc',
      strokeWeight: 1,
      strokeOpacity: 0.5,
      fillOpacity: 0.4,
      strokeStyle: 'solid',
      fillColor: '#1791fc',
      zIndex: 50,
    });
    this.map.add(this.positionRange);
  }

  /** 地图维度切换 */
  is3D = false;
  dimensionChange() {
    this.is3D = !this.is3D;
    this.map.setPitch(this.is3D ? 50 : 0);
  }

  /** 地图类型切换 */
  layers = null;
  mapType = '2';
  setMapType(type) {
    this.mapType = type;
    if (type === '1') {
      this.layers = new this.AMap.TileLayer.Satellite({ map: this.map });
      this.layers && this.layers.show();
    } else {
      this.layers.hide();
    }
  }

  /** 地图层级修改 */
  zoom = 16;
  setZoom(type) {
    if (this.zoom < 3) {
      this.zoom = 3;
      return;
    }
    if (this.zoom > 18) {
      this.zoom = 18;
      return;
    }
    if (type === 'add') {
      this.zoom++;
    } else {
      this.zoom--;
    }
    this.map.setZoom(this.zoom);
  }

  @InjectReactive('deviceDetail') detail;
  @Inject('UPDATE_DEVICE_DETAIL') updateDeviceDetail;
  list = [];
  geofence = '';
  async getList() {
    const list = [];
    let array = await getFenceListById({
      deviceGroupIds: this.detail.deviceId,
    });
    let index = -1;
    array.forEach((item, idx) => {
      if (item.defaultStatus === 1) {
        index = idx;
      }
    });
    if (index !== -1) {
      let results = array.splice(index, 1);
      results.forEach(v => {
        v.geofenceName = `${v.geofenceName}（默认）`;
      });
      list.push(...results, ...array);
    } else {
      list.push(...array);
    }
    list.unshift({
      geofenceId: '-1',
      geofenceName: '无',
    });
    this.list = list;
    this.geofence = this.detail.geofenceId;
    this.handleChange(this.detail.geofenceId);
  }

  zoneArray = [];
  chartList = [];
  async handleChange(val) {
    if (val === '-1') {
      if (this.center.length) {
        this.map.setCenter(this.center);
      }
      this.map.remove(this.chartList);
      return;
    }
    if (this.chartList.length) {
      this.map.remove(this.chartList);
    }
    const item = this.list.find(v => v.geofenceId === val);
    if (item.point && item.point.lng) {
      this.map.setCenter([item.point.lng, item.point.lat]);
    }
    const array = await getZonesData({
      geofenceId: val,
    });
    this.zoneArray = formatToApi(array);
    this.chartList = drawCharts(this.zoneArray, this.AMap, (...args) =>
      mouseEvent.call(null, ...args, this.map),
    );
    this.map.add(this.chartList);
  }

  @Inject('CHANGE_MINI_MAP') showFn;
  handleClose() {
    this.showFn(false);
  }
  @Debounce(500)
  async refreshLocation() {
    const deviceId = this.record.deviceId;
    try {
      // 此接口会触发socket定位的更新
      await updateDevideInfo({ deviceId, type: 1 });
      this.$message.success(this.$t('hat.managementModal.personInfo.toastOne'));
    } catch (error) {
      return false;
    }
  }

  measureDistance() {
    const ruler = new this.AMap.RangingTool(this.map, {
      midLabelText: '{d}米',
      endLabelText: '{d}米',
      d: 1,
    });
    ruler.turnOn();
    ruler.on('end', () => {
      ruler.turnOff();
    });
  }

  async handleBinding() {
    try {
      const result = await createFormModal(
        () => <PositionSetting detail={this.detail} />,
        {
          width: 520,
          title: '设备定位更新频率',
          maskClosable: false,
        },
      );
      if (result) {
        await this.updateDeviceDetail();
        this.getUpdateTime((this.detail.lastStatusTime / 1000).toFixed(0));
      }
    } catch {
      return false;
    }
  }

  formatTime(time) {
    let minute,
      second = 0;
    minute = parseInt(time / 60);
    second = time % 60;
    let str = '';
    if (minute) {
      str += `${minute}分`;
    }
    if (second) {
      str += `${second}秒`;
    }
    return time ? str : 0 + '秒';
  }
}
</script>
<style lang="less" module>
.content {
  position: relative;
  font-size: 14px;
  .selectWrap {
    position: absolute;
    top: 10px;
    right: 50px;
    padding: 3px 10px;
    font-size: 15px;
    background-color: #fff;
    box-shadow: 0 0 3px 1px rgba(131, 131, 131, 0.3);
    border-radius: 6px;
    .label {
      margin-right: 8px;
    }
  }
  .iconBtn {
    height: 36px;
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    top: 10px;
    right: 8px;
    .icon {
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.12);
      color: var(--font);
      font-size: 19px;
      cursor: pointer;
    }
  }
  .timeWrap {
    position: absolute;
    bottom: 28px;
    left: 8px;
    z-index: 1;
    padding: 3px 8px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 0 3px 1px rgba(131, 131, 131, 0.3);
    color: var(--font-info);
    .time {
      color: var(--font);
    }
  }
  .positionValue {
    width: 160px;
    height: 110px;
    position: absolute;
    top: 10px;
    left: 10px;
    background: rgba(255, 255, 255, 0.55);
    box-shadow: 0px 2px 4px 0px rgba(225, 225, 225, 0.5);
    border-radius: 4px;
    backdrop-filter: blur(6px);
    .circleBox {
      display: flex;
      justify-content: center;
      padding-top: 10px;
      gap: 10px;
      .circle {
        width: 68px;
        height: 68px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: rgba(255, 90, 32, 0.1);
        color: rgba(255, 90, 32, 1);
        .value {
          .num {
            font-size: 16px;
            font-weight: 600;
          }
          .unit {
            margin-left: 2px;
            font-size: 12px;
          }
        }
      }
    }
    .updateTime {
      margin-top: 4px;
      text-align: center;
      color: var(--font-info);
      cursor: pointer;
      .timeIcon {
        margin-right: 4px;
        font-size: 16px;
      }
    }
  }
  .toolBar {
    display: flex;
    flex-direction: column;
    gap: 10px 0;
    position: absolute;
    bottom: 75px;
    right: 12px;
    .mapZoom {
      width: 32px;
      height: 66px;
      background: #ffffff;
      box-shadow: 0px 0px 6px 0px #e1e1e1;
      border-radius: 6px;
      .zoomBtn {
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        font-size: 18px;
        cursor: pointer;
      }
      .zoomBtn:first-of-type {
        border-bottom: 1px solid #eeeeee;
      }
    }
    .toolBtn {
      width: 32px;
      height: 33px;
      background: #ffffff;
      box-shadow: 0px 0px 6px 0px #e1e1e1;
      border-radius: 6px;
      cursor: pointer;
      .icon {
        font-size: 18px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        & > svg {
          color: #000;
          height: 20px;
        }
      }
    }
  }
  .mapType {
    display: flex;
    gap: 0 10px;
    position: absolute;
    bottom: 10px;
    right: 12px;
    .typeBox {
      width: 70px;
      height: 50px;
      border: 1px solid transparent;
      position: relative;
      cursor: pointer;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      .title {
        display: inline-block;
        padding: 0 2px;
        position: absolute;
        right: 0;
        bottom: 0;
        color: #fff;
      }
    }
    .typeBox:first-of-type {
      transition: all 0.1s;
      transform: translateX(80px);
    }
    .typeBox:hover {
      border: 1px solid var(--primary);
      .title {
        background-color: var(--primary);
      }
    }
    .selected {
      border: 1px solid var(--primary);
      .title {
        background-color: var(--primary);
      }
    }
  }
  .mapType:hover {
    .typeBox:first-of-type {
      transform: translateX(0);
    }
  }
}

.wrap {
  height: 600px;
  width: 100%;
}
</style>
