<template>
  <div :class="$style.chartBox">
    <div :class="$style.des">
      累计使用：{{ chartData.total }}Mb（注意：当日流量无法查询）
    </div>
    <bar-chart
      :chartData="chartData"
      :isVertical="chartData.isVertical"
      unit="Mb"
      :showLabel="false"
    />
  </div>
</template>

<script>
import { Component, Vue, InjectReactive } from 'vue-property-decorator';
import { getDeviceUsageStatistics } from '@/services/smart-hat/device-management';
import BarChart from '@/views/hat/statistics/bar-chart.vue';
import { Emitter } from '@/utils';

@Component({
  components: {
    BarChart,
  },
})
export default class DosageTraffic extends Vue {
  @InjectReactive('deviceDetail') deviceDetail;
  @InjectReactive('dateRange') dateRange;

  mounted() {
    Emitter.$on('search', this.handleSearch);
    this.getStatistics();
  }
  beforeDestroy() {
    Emitter.$off('search', this.handleSearch);
  }
  handleSearch() {
    this.getStatistics();
  }
  chartData = {};
  async getStatistics() {
    const { startTime, endTime } = this.dateRange;
    const postData = {
      startTime,
      endTime,
      deviceId: this.deviceDetail.deviceId,
      levelEnum: 'TIME',
      type: 'IOT',
    };
    this.chartData = {
      isVertical: false,
      nameList: [],
      dataList: [{ tag: '', values: [] }],
      total: 0,
    };
    const { list, total } = await getDeviceUsageStatistics(postData);
    this.chartData.total = total;
    list.forEach(item => {
      this.chartData.nameList.push(item.titleName);
      this.chartData.dataList[0].values.push({ value: item.total });
    });
  }
}
</script>

<style lang="less" module>
.chartBox {
  height: 100%;
  width: 100%;
  position: relative;
  .des {
    position: absolute;
    top: 5px;
    z-index: 99;
  }
}
</style>
