<template>
  <div style="height: 400px;">
    <div>
      累计使用：{{ usedTotal }}分钟(语音：{{ audio }}分钟 视频：{{ video }}分钟)
      <a-tooltip>
        <template slot="title">
          1、基于设备通话明细中发布的语音、视频统计；<br />
          2、由于音频和视频是独立发布/订阅，故单一设备的音频+视频时长可能大于等于实际在线时长；<br />
          3、平台端用户发布的语音、视频不在统计范围内；<br />
          4、当日的通话明细查询有一天延迟；
        </template>
        <a-icon type="question-circle" style="margin-left: 4px;" />
      </a-tooltip>
    </div>
    <PaginationTable
      ref="tableRef"
      :columns="columns"
      :api="api"
      :extraKeys="{ showKey: false }"
      row-key="pkId"
      :search-params="searchParams"
      :scroll="{ x: 1600, y: 290 }"
      :load="false"
    />
  </div>
</template>

<script>
import { Component, Vue, InjectReactive } from 'vue-property-decorator';
import { getRtcDetailList } from '@/services/smart-hat/device-management';
import FlexColContent from '@/views/hat/components/flex-col-content';
import PaginationTable from '@/views/hat/components/pagination-table';
import { createModal } from '@triascloud/x-components';
// import { Button } from 'ant-design-vue';
import { Emitter } from '@/utils';

@Component({
  components: {
    PaginationTable,
    FlexColContent,
  },
})
export default class DosageRtc extends Vue {
  @InjectReactive('deviceDetail') deviceDetail;
  @InjectReactive('dateRange') dateRange;

  searchParams = {
    startTime: '',
    endTime: '',
    deviceId: '',
  };
  get columns() {
    return [
      {
        align: 'left',
        title: '归属用户',
        width: 120,
        ellipsis: true,
        customRender: (text, record) => (
          <div>
            {record.avatar ? (
              <x-oss-image class={this.$style.avatar} ossPath={record.avatar} />
            ) : null}
            <span>{record.userName}</span>
          </div>
        ),
      },
      {
        align: 'left',
        title: '角色',
        width: 120,
        ellipsis: true,
        customRender: (text, record) => {
          const roleEnum = {
            SENDER: '主叫',
            RECEIVER: '被呼',
          };
          return roleEnum[record.callRole];
        },
      },
      {
        align: 'left',
        title: '接听时间',
        width: 180,
        ellipsis: true,
        customRender: (text, record) =>
          record.createdTs
            ? this.$moment(record.createdTs).format('YYYY-MM-DD HH:mm:ss')
            : '',
      },
      {
        align: 'left',
        title: '结束时间',
        width: 180,
        ellipsis: true,
        customRender: (text, record) =>
          record.destroyedTs
            ? this.$moment(record.destroyedTs).format('YYYY-MM-DD HH:mm:ss')
            : '',
      },
      {
        align: 'left',
        title: '通话网络',
        width: 120,
        ellipsis: true,
        customRender: (text, record) => {
          const signalEnum = {
            'wifi': 'WIFI',
            '4G': '4G',
            'ethernet': '以太网',
          };
          return signalEnum[record.network];
        },
      },
      {
        align: 'left',
        title: '通话质量',
        width: 120,
        ellipsis: true,
        customRender: (text, record) => {
          const qualityEnum = {
            GOOD: '优',
            BAD: '良',
          };
          return qualityEnum[record.callExp];
        },
      },
      {
        align: 'left',
        title: '在线时长',
        width: 120,
        ellipsis: true,
        customRender: (text, record) => {
          return record.onlineDuration
            ? this.formatTime(record.onlineDuration)
            : '';
        },
      },
      {
        align: 'left',
        title: '通话明细',
        width: 150,
        fixed: 'right',
        ellipsis: true,
        customRender: (text, record) => (
          <AButton type="link" onClick={() => this.openDetail(record)}>
            查看明细
          </AButton>
        ),
      },
    ];
  }
  api = getRtcDetailList;
  created() {
    const { startTime, endTime } = this.dateRange;
    this.searchParams.startTime = startTime;
    this.searchParams.endTime = endTime;
    this.searchParams.deviceId = this.deviceDetail.deviceId;
  }
  usedTotal = 0;
  video = 0;
  audio = 0;
  async mounted() {
    Emitter.$on('search', this.handleSearch);
    await this.$refs.tableRef.getTableList();
    const { sizeTotal, videoSize, audioSize } = this.$refs.tableRef.apiData;
    this.usedTotal = (sizeTotal / 60).toFixed(2);
    this.video = (videoSize / 60).toFixed(2);
    this.audio = (audioSize / 60).toFixed(2);
  }
  beforeDestroy() {
    Emitter.$off('search', this.handleSearch);
  }
  handleSearch() {
    const { startTime, endTime } = this.dateRange;
    this.searchParams.startTime = startTime;
    this.searchParams.endTime = endTime;
    this.$refs.tableRef.getTableList();
  }
  // detailModel = null;
  async openDetail(record) {
    const tableList = [
      {
        name: '音频',
        pub: record.pubAudio,
        sub: record.subAudio,
      },
      {
        name: '360P',
        pub: record.pubVideo360,
        sub: record.subVideo360,
      },
      {
        name: '720P',
        pub: record.pubVideo720,
        sub: record.subVideo720,
      },
      {
        name: '1080P',
        pub: record.pubVideo1080,
        sub: record.subVideo1080,
      },
    ];
    let column = [
      {
        title: '通话项',
        dataIndex: 'name',
      },
      {
        title: '上传',
        dataIndex: 'pub',
        customRender: text => (text ? this.formatTime(text) : ''),
      },
      {
        title: '接收',
        dataIndex: 'sub',
        customRender: text => (text ? this.formatTime(text) : ''),
      },
    ];
    this.detailModel = await createModal(
      () => (
        <ATable
          columns={column}
          pagination={false}
          dataSource={tableList}
          transformCellText={({ text }) => text || '-'}
        />
      ),
      {
        title: '通话明细',
        width: '680px',
        // footer: h => [
        //   h(
        //     Button,
        //     {
        //       on: {
        //         click: () => this.detailModel?.handleClose(),
        //       },
        //     },
        //     '取消',
        //   ),
        // ],
      },
    );
  }
  formatTime(time) {
    let minute,
      second = 0;
    minute = parseInt(time / 60);
    second = time % 60;
    let str = '';
    if (minute) {
      str += `${minute}分`;
    }
    if (second) {
      str += `${second}秒`;
    }
    return str;
  }
}
</script>

<style lang="less" module>
.avatar {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  object-fit: cover;
  transform: translateY(28%);
  margin-right: 10px;
}
</style>
