<template>
  <div :class="$style.tabContainer">
    <div :class="$style.tabList">
      <div
        :class="[$style.tab, activeIndex === index ? $style.active : '']"
        v-for="(item, index) in tabList"
        :key="item.name"
        @click="handleChange(index, item.component)"
      >
        {{ item.value }}
      </div>
      <a-range-picker
        v-model="timeRange"
        :ranges="presetTime"
        style="width: 280px;margin-left: auto;"
        :show-time="{ format: 'YYYY-MM-DD' }"
        format="YYYY-MM-DD"
        :allowClear="false"
        :disabled-date="disabledDate"
        @ok="handleSearchTime"
      />
    </div>
    <div :class="$style.tabContent">
      <component :is="componentTag" />
    </div>
  </div>
</template>

<script>
import {
  Component,
  Vue,
  InjectReactive,
  ProvideReactive,
  Prop,
} from 'vue-property-decorator';
import Traffic from './traffic.vue';
import Oss from './oss.vue';
import Rtc from './rtc.vue';
import Message from './message.vue';
import moment from 'moment';
import { Emitter } from '@/utils';

const DAY = 24 * 60 * 60 * 1000;
@Component({
  components: {
    Traffic,
    Oss,
    Rtc,
    Message,
  },
})
export default class Setting extends Vue {
  @Prop({ type: String })
  activeTab;
  @Prop({ type: Object, default: () => {} })
  params;
  @InjectReactive('deviceDetail') deviceDetail;

  @ProvideReactive('dateRange')
  searchParams = {
    startTime: '',
    endTime: '',
  };
  tabList = [
    {
      name: 'traffic',
      value: '流量',
      component: Traffic,
      type: 'PLUS',
    },
    {
      name: 'oss',
      value: '云存储',
      component: Oss,
      type: 'PLUS',
    },
    {
      name: 'rtc',
      value: '通话',
      component: Rtc,
      type: 'PLUS',
    },
    {
      name: 'message',
      value: '短信',
      component: Message,
    },
  ];
  created() {
    this.searchParams.startTime = this.$moment(this.timeRange[0]).valueOf();
    this.searchParams.endTime =
      this.$moment(this.timeRange[1]).valueOf() + DAY - 1000;
    if (this.deviceDetail.type !== 'PLUS') {
      this.tabList = this.tabList.filter(item => !item.type);
    }
    this.componentTag = this.tabList.length ? this.tabList[0].component : '';
  }
  mounted() {
    this.init();
  }
  init() {
    if (this.params) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.params.hasOwnProperty('startTime')) {
        const { startTime, endTime } = this.params;
        this.timeRange = [
          this.$moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
          this.$moment(endTime).format('YYYY-MM-DD HH:mm:ss'),
        ];
        this.searchParams.startTime = this.$moment(this.timeRange[0]).valueOf();
        this.searchParams.endTime = this.$moment(this.timeRange[1]).valueOf();
      }
    }
    if (this.activeTab) {
      const index = this.tabList.findIndex(o => o.name === this.activeTab);
      this.handleChange(index, this.tabList[index].component);
    }
  }
  componentTag = Traffic;
  activeIndex = 0;
  handleChange(index, component) {
    this.componentTag = component;
    this.activeIndex = index;
  }

  timeRange = [
    // 默认显示一周
    this.$moment(new Date().getTime() - 30 * DAY)
      .startOf('date')
      .format('YYYY-MM-DD HH:mm:ss'),
    this.$moment(new Date().getTime())
      .endOf('date')
      .format('YYYY-MM-DD HH:mm:ss'),
  ];
  // 预设时间
  get presetTime() {
    return {
      [this.$t('generalView.filter.day')]: [
        moment().startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('screen.last3')]: [
        moment()
          .add(-3, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('matterwebset.screenmanage.last7')]: [
        moment()
          .add(-7, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('matterwebset.screenmanage.last30')]: [
        moment()
          .add(-30, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
    };
  }
  disabledDate(current) {
    return current > this.$moment().endOf('date'); //禁用今天之后的日期
  }
  handleSearchTime(time) {
    this.searchParams.startTime = this.$moment(time[0]).valueOf();
    this.searchParams.endTime = this.$moment(time[1]).valueOf() + DAY - 1000;
    Emitter.$emit('search');
  }
}
</script>

<style lang="less" module>
.tabContainer {
  box-sizing: border-box;
  .tabList {
    display: flex;
    margin-bottom: 10px;
    .tab {
      padding: 6px 20px;
      cursor: pointer;
      user-select: none;
      border: 1px solid var(--border);
      border-right-color: transparent;
    }
    .tab:first-of-type {
      border-radius: 4px 0 0 4px;
    }
    .tab:last-of-type {
      border-radius: 0 4px 4px 0;
      border-right-color: var(--border);
    }
    .active {
      color: var(--primary);
      border-color: var(--primary) !important;
    }
  }
  .tabContent {
    height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
