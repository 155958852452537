import request from '../request';

const serviceName = '/iot-smart-helmet/fileMark';

/** @name 获取标注 */
export function getFileMark(fileId) {
  return request(`${serviceName}/${fileId}`, {
    method: 'GET',
    closeMessage: true,
  });
}

/** @name 保存标注 */
export function saveFileMark(data) {
  return request(`${serviceName}`, {
    method: 'POST',
    body: data,
  });
}
