<template>
  <div style="height: 400px;">
    <div>累计使用：{{ usedTotal }}条</div>
    <PaginationTable
      ref="tableRef"
      :columns="columns"
      :api="api"
      :extraKeys="{ showKey: false }"
      row-key="pkId"
      :titleSlotKeys="['num']"
      :search-params="searchParams"
      :load="false"
      :scroll="{ x: 1600, y: 290 }"
    >
      <template slot="num"
        >条数
        <a-tooltip
          title="简体中文、字母、数字、标点符号（不区分全角和半角），都按照一个字计算。短信长度不超过70个字，按照一条短信计费；超过70个字，即为长短信，按照67字/条拆分成多条计费。"
        >
          <a-icon type="info-circle" /> </a-tooltip
      ></template>
    </PaginationTable>
  </div>
</template>

<script>
import { Component, Vue, InjectReactive } from 'vue-property-decorator';
import { getMessageDetailList } from '@/services/smart-hat/device-management';
import PaginationTable from '@/views/hat/components/pagination-table';
import { Emitter } from '@/utils';

@Component({
  components: {
    PaginationTable,
  },
})
export default class DosageMessage extends Vue {
  @InjectReactive('deviceDetail') deviceDetail;
  @InjectReactive('dateRange') dateRange;

  searchParams = {
    startTime: '',
    endTime: '',
    deviceId: '',
  };
  get columns() {
    return [
      {
        align: 'left',
        title: '归属用户',
        width: 120,
        ellipsis: true,
        customRender: (text, record) => (
          <div>
            {record.avatar ? (
              <x-oss-image class={this.$style.avatar} ossPath={record.avatar} />
            ) : null}
            <span>{record.userName}</span>
          </div>
        ),
      },
      {
        align: 'left',
        title: '触发时间',
        width: 180,
        ellipsis: true,
        customRender: (text, record) =>
          this.$moment(record.eventTime).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        align: 'left',
        title: '发送号码',
        width: 150,
        ellipsis: true,
        customRender: (text, record) => (
          <div>
            <a-tooltip placement="top">
              <template slot="title">
                {record.phone.length
                  ? record.phone.map(item => <p>{item}</p>)
                  : ''}
              </template>
              <span>{record.phone[0] || '-'}</span>
            </a-tooltip>
          </div>
        ),
      },
      {
        dataIndex: 'num',
        width: 80,
        ellipsis: true,
        slots: { title: 'num' },
      },
      {
        title: '发送内容',
        ellipsis: true,
        dataIndex: 'content',
      },
    ];
  }
  api = getMessageDetailList;
  created() {
    const { startTime, endTime } = this.dateRange;
    this.searchParams.startTime = startTime;
    this.searchParams.endTime = endTime;
    this.searchParams.deviceId = this.deviceDetail.deviceId;
  }
  usedTotal = 0;
  async mounted() {
    Emitter.$on('search', this.handleSearch);
    await this.$refs.tableRef.getTableList();
    this.usedTotal = this.$refs.tableRef.apiData.sizeTotal;
  }
  beforeDestroy() {
    Emitter.$off('search', this.handleSearch);
  }
  handleSearch() {
    const { startTime, endTime } = this.dateRange;
    this.searchParams.startTime = startTime;
    this.searchParams.endTime = endTime;
    this.$refs.tableRef.getTableList();
  }
}
</script>

<style lang="less" module>
.avatar {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  object-fit: cover;
  transform: translateY(28%);
  margin-right: 10px;
}
</style>
