import AntDesignVue, { message } from 'ant-design-vue';
import Element from 'element-ui';
import dayjs from 'dayjs';
import { BaseService } from '@triascloud/services';
import XComponents from '@triascloud/x-components';
import request from './services/request';
import NoDataChart from '@/components/no-data-chart';
import { nowSize } from '@/utils/common.js';
import router from '@/router';
import store from '@/store';

export default function install(Vue) {
  Vue.component('NoDataChart', NoDataChart);
  Vue.use(Element);
  // 全局注册ant-design-vue
  Vue.use(AntDesignVue);
  // 全局注册x-icon
  Vue.use(XComponents, {
    scriptUrl: `//cdn.${process.env.VUE_APP_HOST}/icon/20240904aaXs4tcNsL.js`,

    scriptColorUrl: `//cdn.${process.env.VUE_APP_HOST}/icon/20230830aaVsJXJuqW.js`,
  });
  XComponents.setCommonExtraVueOptions({
    router,
    store,
  });
  // 为Service注入request
  BaseService.request = request;
  Vue.config.productionTip = false;

  Vue.prototype.$moment = dayjs;
  Vue.prototype.$message = message;
  Vue.prototype.$preview = XComponents.Preview.createModal;
  Vue.prototype.$size = nowSize;
}
