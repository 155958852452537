import { promiseLock } from '@triascloud/utils';
import request from '../request';

const serviceName = '/oss/oss';

/**@name 获取文件oss授权 */
export const getAvatar = data =>
  request(`${serviceName}/getAuth`, {
    method: 'POST',
    body: data,
  });

/**@name 获取文件oss授权 */
export const getBackendAuth = data =>
  request(`/oss/backend/getAuth`, {
    method: 'POST',
    body: data,
    auth: false,
  });

/**@name 获取单文件oss授权 */
export const getBackendAuthForOne = data =>
  request(`/oss/backend/getAuth`, {
    method: 'POST',
    body: [data],
    auth: false,
  });

function requestHost() {
  return request(`${serviceName}/host`, {
    method: 'GET',
    auth: false,
    closeMessage: true,
  });
}

/**
 * 获取OSS host路径
 * @return {Promise<T>|*}
 */
export const getHost = promiseLock(requestHost, {
  keyGenerator: () => 'orr-host',
  global: true,
  forever: true,
});

const mapName = `common/map`;
/**
 * 获取中国省份或城市geo
 * @param level
 * @param adcode
 * @return {Promise<T|*>}
 */
export const getGeoData = promiseLock(
  async function(adcode = '') {
    const hostUrl = await getHost();
    const suffixPath =
      adcode === '100000' ? 'china.json' : `code/${adcode}.json`;
    return request(`${hostUrl}/${mapName}/${suffixPath}`, {
      method: 'GET',
      auth: false,
      closeMessage: true,
    });
  },
  {
    keyGenerator: adcode => adcode || 'china',
    global: true,
    forever: true,
  },
);

/**
 * 获取中国geo
 * @return {Promise<T|*>}
 */
export const getChinaData = promiseLock(
  function(url = '') {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then(res => resolve(res.json()))
        .catch(err => reject(err));
    });
  },
  {
    keyGenerator: url => url,
    forever: true,
  },
);
// iot host
export function getIotOssHost() {
  return request(`/oss/iot/oss/host`, {
    method: 'GET',
  });
}

/**@name 音频转码 */
export function audioConvert(data) {
  return request(`/oss/iot/oss/audio/convert`, {
    method: 'POST',
    body: data,
  });
}

/**@name 获取音频转码结果 */
export function getAudioConvertResult(taskId) {
  return request(`/oss/iot/oss/audio/convert/status/${taskId}`, {
    method: 'GET',
  });
}
