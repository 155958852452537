import AMapLoader from '@amap/amap-jsapi-loader';
import * as turf from '@turf/turf';

/** @name 地图图形弹窗的状态 */
export const CHART_DIALOG_KEY = {
  /** @name 只读 */
  READ: 'read',
  /** @name 编辑 */
  UPDATE: 'update',
};

/** @name 图形的类型 */
export const TYPE_CHART = {
  /** @description 圆形 */
  circle: 'circle',
  /** @description 多边形 */
  polygon: 'polygon',
  /** @description 中心点 */
  center: 'center',
};

/** @name 活动or禁止 */
export const TYPE_STATE = {
  /** @description 活动 */
  active: 'active',
  /** @description 禁止 */
  forbid: 'forbid',
  /** @description 中心点 */
  center: 'center',
};

/** @description 活动区域图形的颜色 */
export const ActiveColor = {
  fill: 'rgba(71,113,255,0.1)',
  stroke: '#4771FF',
};
/** @description 禁止区域图形的颜色 */
export const ForbidColor = {
  fill: 'rgba(255,71,71,0.1)',
  stroke: '#ff4747',
};

/**
 * @description 高德AMap对象
 * @param { Object } option 高德'@amap/amap-jsapi-loader'的option
 */
export async function funAMap(option) {
  window._AMapSecurityConfig = {
    // 配置了地图密钥，地图自定义样式才可正常加载
    securityJsCode: process.env.VUE_APP_AMAP_WEB_API_KEY,
  };
  const opt = {
    key: process.env.VUE_APP_AMAP_API_KEY,
    version: '2.0',
    plugins: ['AMap.PolygonEditor', 'AMap.CircleEditor', 'AMap.PlaceSearch'],
  };
  let options = Object.assign(opt, option);
  return await AMapLoader.load(options);
}
// export function terrainMap(Map, AMap, option) {
//   // 卫星图层
//   const satellite = new AMap.TileLayer.Satellite();
//   if (option.viewMode && option.viewMode === '3D') {

//   }
//   const setSatellite = () => {
//     Map.setLayers([satellite]);
//   };
//   const defaultLayers = () => {

//   };
// }
/**
 * @description 批量绘制图形
 * @param { Array } list 图形列表数据
 * @param { Object } AMap 高德对象AMap
 * @param { Function } eventFn 图形回调函数
 */
export function drawCharts(list = [], AMap, eventFn) {
  const result = [];
  const colorFn = (state, key) =>
    state === TYPE_STATE.active ? ActiveColor[key] : ForbidColor[key];
  list.forEach(chart => {
    const opts = {
      zIndex: chart.zIndex,
      strokeColor: colorFn(chart.state, 'stroke'),
      fillColor: colorFn(chart.state, 'fill'),
      strokeWeight: 3,
      extData: {
        _state_: chart.state,
        _name_: chart.name,
        _desc_: chart.desc,
        _id_: chart.id,
        _uid_: chart.id ? chart.id : chart.uid,
        _geofenceId_: chart.geofenceId ? chart.geofenceId : '',
      },
    };
    let newChart = undefined;
    if (chart.type === TYPE_CHART.polygon) {
      newChart = new AMap.Polygon({
        path: chart.path,
        ...opts,
      });
    } else {
      newChart = new AMap.Circle({
        center: chart.center,
        radius: chart.radius,
        ...opts,
      });
    }
    eventFn && eventFn(AMap, newChart);
    chart.target = newChart;
    newChart.extData = {
      ...opts.extData,
    };
    result.push(newChart);
  });
  return result;
}

/** @name 接口数据格式化为区域列表数据 */
export const formatToApi = array => {
  const list = [];
  array.forEach(i => {
    const result = {
      geofenceId: i.geofenceId ? i.geofenceId : '',
      target: '',
      id: i.geofenceZoneId,
      uid: i.geofenceZoneId,
      desc: i.geofenceZoneDesc,
      name: i.geofenceZoneName,
      state: i.geofenceZoneState === 0 ? TYPE_STATE.active : TYPE_STATE.forbid,
      type: i.geofenceZoneType === 0 ? TYPE_CHART.circle : TYPE_CHART.polygon,
    };
    if (i.zone[0]) {
      const pos = i.zone[0].point;
      result['center'] = [pos.lng, pos.lat];
      result['radius'] = i.zone[0].radius;
    } else if (i.zone[1]) {
      const list = [];
      i.zone[1].forEach(v => {
        list.push([v.lng, v.lat]);
      });
      result['path'] = list;
    }
    if (i.settings) {
      result.zIndex = i.settings.zIndex;
    }
    list.push(result);
  });
  list.sort((a, b) => b.zIndex - a.zIndex);
  return list;
};
/** @name 区域列表数据格式化为接口数据 */
export const formatToData = (array, id) => {
  return array.map(item => {
    let zone = {};
    if (item.type === TYPE_CHART.circle) {
      let lng, lat;
      if (Array.isArray(item.center)) {
        lng = item.center[0];
        lat = item.center[1];
      } else {
        lng = item.center.lng;
        lat = item.center.lat;
      }
      zone['0'] = {
        point: {
          lng,
          lat,
        },
        radius: item.radius,
      };
    } else {
      const list = [];
      item.path.forEach(pos => {
        list.push({
          lng: pos[0],
          lat: pos[1],
        });
      });
      zone['1'] = list;
    }
    const result = {
      geofenceId: id,
      geofenceZoneDesc: item.desc,
      geofenceZoneName: item.name,
      geofenceZoneState: item.state === TYPE_STATE.active ? 0 : 1,
      geofenceZoneType: item.type === TYPE_CHART.circle ? 0 : 1,
      zone,
      settings: {
        zIndex: item.zIndex,
      },
    };
    if (item.id) {
      result['geofenceZoneId'] = item.id;
    }
    return result;
  });
};

/** @name hover弹窗 */
const hoverDialog = msg => {
  return `
    <style>
    .worldWrap {
      background-color: rgba(0, 0, 0, 0.6);
      padding: 6px 10px;
      border-radius: 4px;
      position: relative
    }
    .worldWrap::before {
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      content: '';
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-top: 10px solid rgba(0, 0, 0, 0.6);
    }
    .worldWrap .txt {
      color: #fff;
      font-size: 14px;
      display: block;
      min-width: 220px;
    }
    .worldWrap .txt + .txt {
      margin-top: 5px;
    }
    </style>
    
    <section class="worldWrap">
      <span class="txt"
        >名称：${msg._name_}</span
      >
      <span class="txt"
        >类型：${
          TYPE_STATE.active === msg._state_ ? '活动区域' : '禁止区域'
        }</span
      >
      <span class="txt"
        >面积：${msg._area_}平方米</span
      >
      <span class="txt"
        >备注：${msg._desc_ || '-'}</span
      >
    </section>
  `;
};
/** @name AMap地图mouse事件  */
export const mouseEvent = (AMap, chart, mapObject, opts = {}) => {
  chart.on('mouseover', event => {
    let extData = event.target.getExtData();
    let position = [];
    if (Object.keys(opts).length === 0) {
      extData['_area_'] = event.target.getArea();
    }
    if (event.target instanceof AMap.Polygon) {
      const array = [...event.target._opts.path, event.target._opts.path[0]];
      const polygon = turf.polygon([array]);
      const centroid = turf.centroid(polygon);
      position = centroid.geometry.coordinates;
    } else {
      position = event.target._opts.center;
    }

    const textField = new AMap.Marker({
      content: hoverDialog(extData),
      position,
      offset: new AMap.Pixel(-120, -180),
      zIndex: 101,
      ...opts,
    });

    event.target.textField = textField;
    mapObject.add(event.target.textField);
  });
  chart.on('mouseout', event => {
    event.target.textField &&
      mapObject.remove(event.target.textField) &&
      (event.target.textField = null);
  });
};

/** @description 1x的速度，为5km/h */
export const INIT_SPEED = 5;
/**
 * 轨迹回放
 * @param {*} map 地图map
 * @param {*} path Array<Array<Lng, lat>>
 * @param {*} callback move事件返回【下标】
 * @returns navigator轨迹导航器
 */
export const createPathSimplifier = (map, path, callback, options) => {
  return new Promise(resolve => {
    const Color = {
      g1: '#28F',
      g2: '#282',
      b: '#ababab',
      r: '#ff5a20',
    };
    window.AMapUI.loadUI('misc/PathSimplifier', PathSimplifier => {
      const pathSimplifier = new PathSimplifier({
        zIndex: 100,
        map,
        renderOptions: {
          // startPointStyle: {
          //   fillStyle: Color.b,
          //   strokeStyle: Color.b,
          //   radius: 10,
          // },
          // endPointStyle: {
          //   fillStyle: Color.r,
          //   strokeStyle: Color.r,
          //   radius: 10,
          // },
          //轨迹线的样式
          pathLineStyle: {
            strokeStyle: Color.b,
            lineWidth: 6,
            dirArrowStyle: true,
          },
          pathLineSelectedStyle: {
            strokeStyle: Color.b,
          },
          pathLineHoverStyle: {
            lineWidth: 6,
            strokeStyle: Color.b,
          },
        },
        getPath: d => d.path,
        getHoverTitle: () => '',
      });
      pathSimplifier.setData([
        {
          name: '',
          path: path,
        },
      ]);
      const navigator = pathSimplifier.createPathNavigator(0, {
        // loop: true,
        speed: INIT_SPEED,
        pathNavigatorStyle: {
          autoRotate: false,
          fillStyle: 'rgba(0,0,0,0)',
          strokeStyle: Color.g1,
          // content: options
          //   ? PathSimplifier.Render.Canvas.getImageContent(options.content)
          //   : 'circle',
          content: options
            ? (ctx, x, y, width, height) => {
                const r = width / 2;
                const renderArcImg = (img, x, y, r) => {
                  ctx.save();
                  ctx.arc(x + r, y + r, r, 0, 2 * Math.PI);
                  ctx.clip();
                  ctx.drawImage(img, x, y, width, height);
                  ctx.restore();
                };
                const img = new Image();
                img.src = options.content;
                renderArcImg(img, x, y, r);
              }
            : 'circle',
          width: (options && options.width) ?? 20,
          height: (options && options.height) ?? 20,
          pathLinePassedStyle: {
            dirArrowStyle: true,
            lineWidth: 6,
            strokeStyle: Color.g1,
          },
        },
      });

      let idx = 0;
      navigator.on('move', data => {
        if (idx !== data.target.cursor.idx) {
          idx = data.target.cursor.idx;
          callback && callback(idx);
        }
      });
      // map.setFitView();
      // const zoom = map.getZoom();
      // map.setZoom(zoom + 1);
      pathSimplifier.setFitView(-1);
      resolve([navigator, pathSimplifier]);
    });
  });
};
