import request from '../request';

const serviceName = '/iot-smart-helmet/device/file';

/** @name 文件列表 */
export function getFileList(data) {
  return request(serviceName, {
    method: 'GET',
    body: data,
  });
}

/** @name 文件同步 */
export function syncFile(fileId) {
  return request(`${serviceName}/sync/${fileId}`, {
    method: 'PUT',
  });
}

/** @name 文件同步状态统计 */
export function getFileStatistics(data) {
  return request(`${serviceName}/countCloudStatus`, {
    method: 'GET',
    body: data,
  });
}

/** @name 删除文件 */
export function deleteFile(data) {
  return request(serviceName, {
    method: 'DELETE',
    body: data,
  });
}

/** @name 文件成员列表 */
export function getUserList(deviceId) {
  return request(`${serviceName}/user/${deviceId}`, {
    method: 'GET',
  });
}

/** @name 修改文件备注 */
export function putRemark(data) {
  return request(`${serviceName}/mark`, {
    method: 'PUT',
    body: data,
  });
}

/** @name 文件管理-列表查询 */
export function fileTable(data) {
  return request(`${serviceName}/filePage`, {
    method: 'POST',
    body: data,
  });
}

/** @name 文件管理-列表查询-导出 */
export function fileTableDownload(data) {
  return request(`${serviceName}/exportFileManage`, {
    method: 'POST',
    body: data,
  });
}

/** @name 文件管理-文件详情 */
export function getFileDetail(fileId) {
  return request(`${serviceName}/fileDetail/${fileId}`, {
    method: 'GET',
  });
}

/** @name 文件管理-地图模式-列表 */
export function fileMapList(data) {
  return request(`${serviceName}/fileMap`, {
    method: 'GET',
    body: data,
  });
}

/** @name 文件管理-地图模式-文件详情 */
export function fileMapDetail(data) {
  return request(`${serviceName}/fileMapDetail`, {
    method: 'GET',
    body: data,
  });
}

/** @name 文件管理-地图模式-文件详情-加载更多 */
export function moreFileMapDetail(data) {
  return request(`${serviceName}/fileMapDetailMore`, {
    method: 'GET',
    body: data,
  });
}

/** @name 文件管理-语音识别-获取文字识别内容 */
export function getRecognitionContent(fileId) {
  return request(`/iot-smart-helmet/captionRelated/${fileId}`, {
    method: 'GET',
  });
}
