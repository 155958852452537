<template>
  <div :class="$style.tabContainer">
    <div :class="$style.tabList">
      <div
        :class="[$style.tab, activeIndex === index ? $style.active : '']"
        v-for="(item, index) in tabList"
        :key="item.name"
        @click="handleChange(index, item.component)"
      >
        {{ item.value }}
      </div>
    </div>
    <div :class="$style.tabContent">
      <component :is="componentTag" />
    </div>
  </div>
</template>

<script>
import { Component, Vue, InjectReactive } from 'vue-property-decorator';
import Rules from './rules.vue';
import Notify from './notify-setting.vue';
import DeviceDebug from './device-debug.vue';
import OtherSetting from './other-setting.vue';
import Camera from './camera.vue';
@Component({
  components: {
    Rules,
    Notify,
    DeviceDebug,
    OtherSetting,
  },
})
export default class Setting extends Vue {
  @InjectReactive('deviceDetail') deviceDetail;

  tabList = [
    {
      name: 'rules',
      value: this.$t('hat.managementModal.setting.rule'),
      component: Rules,
    },
    {
      name: 'notify',
      value: this.$t('hat.managementModal.setting.notice'),
      component: Notify,
    },
    {
      name: 'other',
      value: this.$t('hat.managementModal.setting.other'),
      component: OtherSetting,
    },
    {
      name: 'camera',
      value: this.$t('hat.managementModal.setting.camera'),
      component: Camera,
      type: 'PLUS',
    },
    {
      name: 'debug',
      value: this.$t('hat.managementModal.setting.deviceBug'),
      component: DeviceDebug,
    },
  ];
  created() {
    if (this.deviceDetail.type !== 'PLUS') {
      this.tabList = this.tabList.filter(item => !item.type);
    }
    this.componentTag = this.tabList.length ? this.tabList[0].component : '';
  }
  componentTag = Rules;
  activeIndex = 0;
  handleChange(index, component) {
    this.componentTag = component;
    this.activeIndex = index;
  }
}
</script>

<style lang="less" module>
.tabContainer {
  box-sizing: border-box;
  .tabList {
    display: flex;
    margin-bottom: 10px;
    .tab {
      padding: 6px 20px;
      cursor: pointer;
      user-select: none;
      border: 1px solid var(--border);
      border-right-color: transparent;
    }
    .tab:first-of-type {
      border-radius: 4px 0 0 4px;
    }
    .tab:last-of-type {
      border-radius: 0 4px 4px 0;
      border-right-color: var(--border);
    }
    .active {
      color: var(--primary);
      border-color: var(--primary) !important;
    }
  }
  .tabContent {
    height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
