<template>
  <div :class="$style.wrap">
    <div :class="$style.boxWrap">
      <div :class="$style.imgWrap">
        <img
          @click="handleChange(0)"
          :class="current === 0 ? $style.actived : ''"
          src="@/assets/images/smart-hat/trajectory/mode0.png"
        />
      </div>
      <div :class="$style.imgWrap">
        <img
          @click="handleChange(1)"
          :class="current === 1 ? $style.actived : ''"
          src="@/assets/images/smart-hat/trajectory/mode1.png"
        />
        <span v-if="watermark" :class="$style.markWrap">
          <span>Sandieyun AISCAP Plus</span>
        </span>
        <div :class="$style.openWrap">
          <a-checkbox v-model="watermark">
            开启Sandieyun水印
          </a-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component()
export default class SetVideoTrajectoryMode extends Vue {
  @Prop({ type: Object }) detail;

  mounted() {
    this.watermark = this.detail.watermark;
    this.current = this.detail.value;
  }

  watermark = false;
  current = 0;
  handleChange(val) {
    this.current = val;
    if (this.current === 0) {
      this.watermark = false;
    }
  }

  getValue() {
    return { value: this.current, watermark: this.watermark };
  }
}
</script>
<style lang="less" module>
.wrap {
  .boxWrap {
    display: flex;
    justify-content: space-between;
  }
  .imgWrap {
    position: relative;
    img {
      height: 210px;
      width: 343px;
    }
    .actived {
      outline: 1px solid var(--danger);
    }
    .markWrap {
      position: absolute;
      bottom: 37px;
      right: 12px;
      line-height: 1;
      color: #fff;
      font-size: 12px;
    }
  }
  .openWrap {
    height: 21px;
  }
}
</style>
