<template>
  <div :class="$style.tabContainer">
    <div :class="$style.tabList">
      <div
        :class="[$style.tab, activeIndex === index ? $style.active : '']"
        v-for="(item, index) in tabList"
        :key="item.name"
        @click="handleChange(index)"
      >
        {{ item.value }}
      </div>
      <a-form-model layout="inline" :class="$style.formWrapper">
        <a-form-model-item>
          <a-range-picker
            v-model="searchForm.timeRange"
            :ranges="presetTime"
            style="width: 310px;"
            :show-time="{ format: 'HH:mm' }"
            format="YYYY-MM-DD HH:mm"
            :disabled-date="disabledDate"
            :placeholder="[
              $t('hat.clockRecord.startDate'),
              $t('hat.clockRecord.endDate'),
            ]"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            style="width: 105px"
            v-model="searchForm.user"
            :placeholder="$t('hat.managementModal.event.member')"
            allow-clear
          >
            <a-select-option
              v-for="item in userList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            style="width: 105px"
            v-model="searchForm.event"
            :placeholder="$t('hat.managementModal.event.allEvent')"
            allow-clear
          >
            <a-select-option
              v-for="item in eventList"
              :key="item.id"
              :value="item.id"
            >
              {{ locale === 'en_US' ? item.id : item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <div>
            <a-button type="primary" :class="$style.btn" @click="handleSearch">
              {{ $t('hat.managementModal.event.search') }}
            </a-button>
            <a-button :class="$style.btn" @click="resetForm">
              {{ $t('msg.reset') }}
            </a-button>
          </div>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div :class="$style.tabContent">
      <component :is="componentTag" :eventList="eventList" ref="wrapperRef" />
    </div>
  </div>
</template>

<script>
import { Component, InjectReactive, Vue } from 'vue-property-decorator';
import moment from 'moment';
import Details from './details.vue';
import Statement from './statement.vue';
import { getSearchParamList } from '@/services/smart-hat/device';
import { crossStorageModule } from '@/enum/store.js';

@Component({
  components: {
    Details,
    Statement,
  },
})
export default class Events extends Vue {
  @crossStorageModule.State locale;

  componentTag = Details;

  mounted() {
    this.getSearchList();
  }

  disabledDate(current) {
    return current > this.$moment().endOf('date'); //禁用今天之后的日期
  }
  searchForm = {
    timeRange: [],
    user: undefined,
    event: undefined,
  };
  // 预设时间
  get presetTime() {
    return {
      [this.$t('generalView.filter.day')]: [
        moment().startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('screen.last3')]: [
        moment()
          .add(-3, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('matterwebset.screenmanage.last7')]: [
        moment()
          .add(-7, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('matterwebset.screenmanage.last30')]: [
        moment()
          .add(-30, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
    };
  }

  userList = [];
  eventList = [];
  @InjectReactive('deviceDetail') deviceDetail;
  async getSearchList() {
    const data = await getSearchParamList(this.deviceDetail.deviceId);
    this.userList = data.user;
    this.eventList = data.subType;
  }
  handleSearch() {
    // todo get Data
    this.$refs.wrapperRef.fetchData(this.searchForm, true);
  }
  resetForm() {
    this.searchForm = {
      timeRange: [],
      user: undefined,
      event: undefined,
    };
  }

  tabList = [
    {
      name: 'details',
      value: this.$t('hat.managementModal.event.detail'),
    },
    {
      name: 'statement',
      value: this.$t('hat.managementModal.event.statement'),
    },
  ];
  activeIndex = 0;
  handleChange(index) {
    this.resetForm();
    index === 0
      ? (this.componentTag = Details)
      : (this.componentTag = Statement);
    this.activeIndex = index;
  }
}
</script>

<style lang="less" module>
.tabContainer {
  box-sizing: border-box;
  .tabList {
    display: flex;
    margin-bottom: 10px;
    .tab {
      min-width: 68px;
      padding: 0 2px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      cursor: pointer;
      user-select: none;
      border: 1px solid var(--border);
      border-right-color: transparent;
    }
    .tab:first-of-type {
      border-radius: 4px 0 0 4px;
    }
    .tab:last-of-type {
      border-radius: 0 4px 4px 0;
      border-right-color: var(--border);
    }
    .active {
      color: var(--primary);
      border-color: var(--primary) !important;
    }
  }
  .tabContent {
  }
  .formWrapper {
    margin-left: 10px;
  }
  .btn {
    min-width: 50px;
    height: 31px;
  }
  .btn + .btn {
    margin-left: 10px;
  }
}
</style>
