<template>
  <div :class="$style.rules">
    <Hint />
    <div :class="$style.content">
      <div :class="$style.buttonGroups">
        <a-button
          :class="$style.buttonItem"
          type="primary"
          @click="isEdit = true"
          v-if="!isEdit && $p.action('UPDATE')"
          >{{ $t('hat.deviceManagement.edit') }}</a-button
        >
        <a-button
          :class="$style.buttonItem"
          type="default"
          @click="isEdit = false"
          v-if="isEdit"
          >{{ $t('msg.cancel') }}</a-button
        >
        <a-button
          :class="$style.buttonItem"
          type="primary"
          @click="handleSave"
          v-if="isEdit"
          >{{ $t('msg.save') }}</a-button
        >
      </div>
      <div v-for="(item, key) in rulesForm" :key="key" :class="$style.ruleItem">
        <a-row :class="$style.check" style="width: 84%;">
          <a-checkbox v-model="item.enable" :disabled="!isEdit">
            <span style="margin-left: 10px">{{ item.name }}</span>
            <span :class="$style.dsc">{{ item.tips }}</span>
          </a-checkbox>
        </a-row>
        <a-row :class="$style.check">
          <a-col :offset="1">
            {{ $t('hat.managementModal.setting.hint.rule.judgingConditions') }}
          </a-col>
          <a-col :offset="1">
            <a-radio-group v-model="item.checkinFirst" :disabled="!isEdit">
              <a-radio value="conditionFirst">
                <span>{{
                  $t('hat.managementModal.setting.hint.rule.defaultConditions')
                }}</span>
              </a-radio>
              <a-radio value="checkinFirst">
                <span :class="$style.marginR">{{
                  $t('hat.managementModal.setting.hint.rule.attendanceRules')
                }}</span>
                <a-select
                  style="width: 150px"
                  v-model="item.priority"
                  :disabled="!isEdit"
                  v-show="item.checkinFirst === 'checkinFirst'"
                >
                  <a-select-option value="ALL_TIME">{{
                    $t('hat.managementModal.setting.hint.rule.includeBreaks')
                  }}</a-select-option>
                  <a-select-option value="WORK_TIME">{{
                    $t('hat.managementModal.setting.hint.rule.excludeBreaks')
                  }}</a-select-option>
                </a-select>
              </a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row
          :class="$style.check"
          v-if="item.checkinFirst === 'conditionFirst'"
        >
          <a-col :offset="1">
            {{ $t('hat.managementModal.setting.hint.rule.defaultConditions') }}
          </a-col>
          <a-col :offset="1">
            <span :class="$style.marginR">{{
              $t('hat.managementModal.setting.hint.rule.triggerPeriod')
            }}</span>
            <a-time-picker
              v-model="item.defaultStartTime"
              :disabled="!isEdit"
              format="HH:mm"
              style="width: 100px"
              :placeholder="$t('common.startTime')"
            />
            <span style="margin: 0 10px">-</span>
            <a-time-picker
              v-model="item.defaultEndTime"
              :disabled="!isEdit"
              format="HH:mm"
              style="width: 100px"
              :placeholder="$t('common.endTime')"
            />
          </a-col>
          <a-col :offset="1">
            <span :class="$style.marginR">{{
              $t('hat.managementModal.setting.hint.rule.triggerArea')
            }}</span>
            <a-select
              style="width: 120px"
              v-model="item.defaultLocation"
              :placeholder="
                $t('hat.managementModal.setting.hint.rule.selectRegion')
              "
              :disabled="!isEdit"
            >
              <a-select-option
                value="ALL"
                v-if="['abnormalStatic', 'positionCheck'].includes(key)"
              >
                {{ $t('hat.managementModal.setting.hint.rule.Anywhere') }}
              </a-select-option>
              <a-select-option
                v-for="item in fenceList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
        <a-row :class="$style.check" v-if="key === 'abnormalStatic'">
          <a-col :offset="1">
            {{ $t('hat.managementModal.setting.hint.rule.detectionMethod') }}
          </a-col>
          <a-col :offset="1">
            <a-radio-group
              v-model="item.detectionMode"
              :disabled="!isEdit"
              @change="handleCheckChange"
            >
              <a-radio value="ACCELEROMETER">
                <span>{{
                  $t('hat.managementModal.setting.hint.rule.Accelerometer')
                }}</span>
                <a-tooltip>
                  <template slot="title">
                    {{
                      $t(
                        'hat.managementModal.setting.hint.rule.AccelerometerTips',
                      )
                    }}
                  </template>
                  <a-icon type="question-circle" style="margin: 4px 0 0 5px;" />
                </a-tooltip>
              </a-radio>
              <a-radio value="LOCATION_RANGE">
                <span>{{
                  $t('hat.managementModal.setting.hint.rule.positioningRange')
                }}</span>
                <a-tooltip>
                  <template slot="title">
                    {{
                      $t(
                        'hat.managementModal.setting.hint.rule.positioningRangeTips',
                      )
                    }}
                  </template>
                  <a-icon type="question-circle" style="margin: 4px 0 0 5px;" />
                </a-tooltip>
              </a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row
          :class="$style.check"
          v-if="
            key !== 'hatRules' &&
              key !== 'positionCheck' &&
              item.detectionMode !== 'ACCELEROMETER'
          "
        >
          <a-col :offset="1">
            <span v-if="key !== 'abnormalStatic'">{{
              $t('hat.managementModal.setting.hint.rule.distanceError')
            }}</span>
            <span v-else>{{
              $t('hat.managementModal.setting.hint.rule.staticRange')
            }}</span>
          </a-col>
          <a-col :offset="1">
            <a-input-number
              v-model="item.distanceError"
              :disabled="!isEdit"
              :min="0"
              :max="1000"
            />
            <span style="margin-left: 10px">{{ $t('screen.meter') }}</span>
          </a-col>
        </a-row>
        <a-row :class="$style.check" v-if="key === 'abnormalStatic'">
          <a-col :offset="1">
            <span>{{
              $t('hat.managementModal.setting.hint.rule.staticCondition')
            }}</span>
          </a-col>
          <a-col :offset="1">
            <span :class="$style.marginR">{{
              $t('hat.managementModal.setting.hint.rule.timeExceeds')
            }}</span>
            <a-select
              v-model="item.duration"
              :disabled="!isEdit"
              default
              style="width: 80px;"
            >
              <a-select-option
                v-for="(i, index) in durationArr"
                :key="index"
                :value="i"
                >{{ i }}</a-select-option
              >
            </a-select>
            <span style="margin-left: 10px">{{
              $t('hat.managementModal.setting.hint.rule.minutes')
            }}</span>
          </a-col>
        </a-row>
        <a-row :class="$style.check" v-if="key === 'positionCheck'">
          <a-col :offset="1">
            <span>{{
              $t('hat.managementModal.setting.hint.rule.Verification')
            }}</span>
          </a-col>
          <a-col :offset="1">
            <span :class="$style.marginR">{{
              $t('hat.managementModal.setting.hint.rule.gap')
            }}</span>
            <a-select
              v-model="item.checkInterval"
              :disabled="!isEdit"
              default
              style="width: 70px;"
            >
              <a-select-option
                v-for="(i, index) in checkArr"
                :key="index"
                :value="i * 60"
                >{{ i.toFixed(1) }}</a-select-option
              >
            </a-select>
            <span style="margin-left: 10px"
              >{{ $t('hat.managementModal.setting.hint.rule.HoursTime')
              }}<a-tooltip>
                <template slot="title">
                  {{ $t('hat.managementModal.setting.hint.rule.tips') }}
                </template>
                <a-icon
                  type="question-circle"
                  style="margin: 4px 0 0 5px;"
                /> </a-tooltip
            ></span>
          </a-col>
          <a-col :offset="1">
            <span style="margin-right: 10px">{{
              $t('hat.managementModal.setting.hint.rule.VerificationError')
            }}</span>
            <a-input-number
              v-model="item.distanceError"
              :disabled="!isEdit"
              :min="0"
              :max="1000"
            />
            <span style="margin-left: 10px">{{
              $t('hat.managementModal.setting.hint.rule.metre')
            }}</span>
          </a-col>
        </a-row>
        <a-row :class="$style.check" v-if="key === 'positionCheck'">
          <a-col :offset="1">
            <span>{{
              $t(
                'hat.managementModal.setting.hint.rule.VerificationValidityPeriod',
              )
            }}</span>
          </a-col>
          <a-col :offset="1">
            <a-select
              v-model="item.duration"
              :disabled="!isEdit"
              default
              style="width: 80px;"
            >
              <a-select-option
                v-for="(i, index) in durationArr"
                :key="index"
                :value="i"
                >{{ i }}</a-select-option
              >
            </a-select>
            <span style="margin-left: 10px">{{
              $t('hat.managementModal.setting.hint.rule.minutes')
            }}</span>
          </a-col>
        </a-row>
        <a-row :class="$style.check">
          <a-col :offset="1">
            {{ $t('hat.managementModal.setting.hint.rule.reminderVoice') }}
          </a-col>
          <a-col :offset="1" style="width: 500px;">
            <a-input
              v-model="item.content"
              :disabled="!isEdit"
              :placeholder="$t('common.enter')"
              @blur="onBlur($event, key)"
            />
          </a-col>
        </a-row>
      </div>
    </div>
    <a-spin :spinning="loading" />
  </div>
</template>

<script>
import { Component, Vue, InjectReactive } from 'vue-property-decorator';
import { getFenceListById } from '@/services/smart-hat/geofence';
import moment from 'moment';
import { getRuleList, putRule } from '@/services/smart-hat/device-management';
import Hint from './hint.vue';
import { replaceContent } from '@/views/hat/utils.js';

@Component({
  components: {
    Hint,
  },
})
export default class Rules extends Vue {
  @InjectReactive('deviceDetail') deviceDetail;

  isEdit = false;
  rulesForm = {
    hatRules: {
      type: 'UN_CAP',
      name: this.$t('hat.managementModal.setting.hint.rule.uncapping'),
      tips: `（${this.$t(
        'hat.managementModal.setting.hint.rule.uncappingTips',
      )}）`,
      enable: false,
      defaultStartTime: null,
      defaultEndTime: null,
      priority: 'ALL_TIME',
      checkinFirst: 'checkinFirst',
      defaultLocation: 'ALL',
      distanceError: 0,
      duration: 0,
      content: '',
      detectionMode: null,
    },
    crossRules: {
      type: 'OUT_OF_BOUNDS',
      name: this.$t('hat.managementModal.setting.hint.rule.overstep'),
      tips: `（${this.$t(
        'hat.managementModal.setting.hint.rule.overstepTips',
      )}）`,
      enable: false,
      defaultStartTime: null,
      defaultEndTime: null,
      priority: 'ALL_TIME',
      checkinFirst: 'checkinFirst',
      defaultLocation: 'ALL',
      distanceError: 0,
      duration: 0,
      content: '',
      detectionMode: null,
    },
    leaveRules: {
      type: 'OFF_DUTY',
      name: this.$t('hat.managementModal.setting.hint.rule.absence'),
      tips: `（${this.$t(
        'hat.managementModal.setting.hint.rule.absenceTips',
      )}）`,
      enable: false,
      defaultStartTime: null,
      defaultEndTime: null,
      priority: 'ALL_TIME',
      checkinFirst: 'checkinFirst',
      defaultLocation: 'ALL',
      distanceError: 0,
      duration: 0,
      content: '',
      detectionMode: null,
    },
    abnormalStatic: {
      type: 'UNUSUALLY_STILL',
      name: this.$t('hat.managementModal.setting.hint.rule.abnormalStandstill'),
      tips: `（${this.$t(
        'hat.managementModal.setting.hint.rule.abnormalStandstillTips',
      )}）`,
      enable: false,
      defaultStartTime: null,
      defaultEndTime: null,
      priority: 'ALL_TIME',
      checkinFirst: 'checkinFirst',
      defaultLocation: 'ALL',
      distanceError: 0,
      duration: 1,
      content: '',
      detectionMode: 'ACCELEROMETER',
    },
    positionCheck: {
      type: 'CHECK_LOCATION',
      name: this.$t('hat.managementModal.setting.hint.rule.positionCheck'),
      tips: `（${this.$t(
        'hat.managementModal.setting.hint.rule.positionCheckTips',
      )}）`,
      enable: false,
      defaultStartTime: null,
      defaultEndTime: null,
      priority: 'ALL_TIME',
      checkinFirst: 'checkinFirst',
      defaultLocation: 'ALL',
      distanceError: 0,
      duration: 1,
      content: '',
      detectionMode: null,
      checkInterval: 30,
    },
  };
  mounted() {
    this.getFenceList();
    this.getRules();
  }
  fenceList = [];
  async getFenceList() {
    const data = {
      deviceGroupIds: this.deviceDetail.deviceId,
    };
    const records = await getFenceListById(data);
    this.fenceList = records.map(item => ({
      value: item.geofenceId,
      label: item.geofenceName,
    }));
  }
  loading = false;
  async getRules() {
    this.loading = true;
    try {
      const enumList = {
        UN_CAP: 'hatRules',
        OUT_OF_BOUNDS: 'crossRules',
        OFF_DUTY: 'leaveRules',
        UNUSUALLY_STILL: 'abnormalStatic',
        CHECK_LOCATION: 'positionCheck',
      };
      const data = await getRuleList(this.deviceDetail.deviceId);
      this.loading = false;
      data.forEach(item => {
        if (
          [
            'UN_CAP',
            'OUT_OF_BOUNDS',
            'OFF_DUTY',
            'UNUSUALLY_STILL',
            'CHECK_LOCATION',
          ].includes(item.type)
        ) {
          if (['UN_CAP', 'OUT_OF_BOUNDS', 'OFF_DUTY'].includes(item.type)) {
            // 脱帽规则/越界规则/离岗规则的触发区域不包含任意地点,后端返回的ALL需转为空
            item.defaultLocation === 'ALL' && (item.defaultLocation = '');
          }
          item.defaultStartTime = item.defaultStartTime
            ? moment(item.defaultStartTime, 'HH:mm')
            : null;
          item.defaultEndTime = item.defaultEndTime
            ? moment(item.defaultEndTime, 'HH:mm')
            : null;
          this.rulesForm[enumList[item.type]] = {
            ...this.rulesForm[enumList[item.type]],
            ...item,
          };
          if (item.priority === 'DEFAULT_TERM') {
            this.rulesForm[enumList[item.type]].checkinFirst = 'conditionFirst';
            this.rulesForm[enumList[item.type]].priority = 'ALL_TIME';
          }
        }
      });
    } catch {
      this.loading = false;
    }
  }
  async handleSave() {
    const postData = {
      deviceId: this.deviceDetail.deviceId,
      reminders: [],
    };
    for (let key in this.rulesForm) {
      // eslint-disable-next-line no-unused-vars
      const { name, tips, checkinFirst, ...data } = this.rulesForm[key];
      if (this.rulesForm[key].checkinFirst === 'conditionFirst') {
        data.priority = 'DEFAULT_TERM';
      }
      data.defaultEndTime = data.defaultEndTime
        ? this.$moment(data.defaultEndTime).format('HH:mm:ss')
        : null;
      data.defaultStartTime = data.defaultStartTime
        ? this.$moment(data.defaultStartTime).format('HH:mm:ss')
        : null;
      if (
        (!data.defaultEndTime && data.defaultStartTime) ||
        (data.defaultEndTime && !data.defaultStartTime)
      ) {
        this.$message.warning(
          `${this.rulesForm[key].name}的自定义触发时间段需补全起止时间！`,
        );
        return;
      }
      if (data.defaultEndTime < data.defaultStartTime) {
        this.$message.warning(
          `${this.rulesForm[key].name}的开始时间不能大于结束时间！`,
        );
        return;
      }
      if (
        data.enable &&
        !data.defaultLocation &&
        checkinFirst === 'conditionFirst'
      ) {
        this.$message.warning(
          `${this.rulesForm[key].name}的触发区域不能为空！`,
        );
        return;
      }
      postData.reminders.push(data);
    }
    await putRule(postData);
    await this.$message.success({
      content: '保存成功！',
      duration: 0.5,
    });
    this.isEdit = false;
    this.getRules();
  }
  checkArr = [0.5, 1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0];
  durationArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  handleCheckChange(e) {
    if (e.target.value === 'LOCATION_RANGE') {
      this.rulesForm['abnormalStatic'].duration = 30;
      this.durationArr = [30, 45, 60, 80, 100, 120];
    } else {
      this.rulesForm['abnormalStatic'].duration = 1;
      this.durationArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    }
  }
  onBlur(e, key) {
    const { value } = e.target;
    this.rulesForm[key].content = replaceContent(value);
  }
}
</script>

<style lang="less" module>
.rules {
  .content {
    position: relative;
    padding-top: 15px;
    .ruleItem {
      margin-bottom: 20px;
    }
    .buttonGroups {
      position: absolute;
      z-index: 9999;
      right: 10px;
      top: 6px;
      .buttonItem {
        min-width: 50px;
        height: 30px;
        margin-left: 10px;
      }
    }
    .check {
      margin-bottom: 12px;
      display: flex;
      align-items: center;
    }
    .marginR {
      margin-right: 10px;
    }
    .dsc {
      color: var(--font-info);
    }
  }
}
</style>
