import request from '../request';

const serviceName = '/iot-smart-helmet/screen/share';

/** @name 创建分享 */
export function shareGenerate(data) {
  return request(`${serviceName}`, {
    method: 'POST',
    body: data,
  });
}

/** @name 判断分享是否有效 */
export function checkShare(data) {
  return request(`${serviceName}/checkShareExpire`, {
    method: 'GET',
    body: data,
    auth: false,
    closeMessage: true,
  });
}

/** @name 获取设备列表 */
export function getDeviceList(data, shareData) {
  return request(`${serviceName}/list`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/** @name 获取底部类型数据 */
export function getTypeStatic(data, shareData) {
  return request(`${serviceName}/count`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/** @name 获取关键指标 */
export function getTarget(data, shareData) {
  return request(`${serviceName}/target`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/** @name 下拉选择编组列表 */
export function getGroupList(data, shareData) {
  return request(`${serviceName}/loadDeviceGroup`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/** @name 下拉选择项目列表 */
export function getProjectList(data, shareData) {
  return request(`${serviceName}/pagePm`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/**
 * 在线时长统计
 * @param {object} data
 * @param {'GROUP' | 'PEOPLE'} data.statisticsPointEnum
 * @returns
 */
export function onlineDurationStatistics(data, shareData) {
  return request(`${serviceName}/statistics/onSiteBoard`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/**
 * 离场统计
 * @param {object} data
 * @param {'GROUP' | 'PEOPLE'} data.statisticsPointEnum
 * @returns
 */
export function departureRanking(data, shareData) {
  return request(`${serviceName}/statistics/offSiteBoard`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/**
 * 时长占比统计
 * @param {object} data
 * @param {'GROUP' | 'PEOPLE'} data.statisticsPointEnum 按人/按组
 * @param {'CURRENT_DAY' | 'SEVEN_DAY' | 'THIRTY_DAY'} data.searchDayEnum 时间范围
 * @param {string} data.groupIdList 编组IDS id,id,id
 * @returns
 */
export function durationStatistics(data, shareData) {
  return request(`${serviceName}/statistics/proportion`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/**
 * 设备事件
 * @param {object} data
 * @param {'GROUP' | 'PEOPLE'} data.statisticsPointEnum
 * @returns
 */
export function deviceEvent(data, shareData) {
  return request(`${serviceName}/event`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/**
 * 重点关注成员
 * @param {object} data
 * @param {'GROUP' | 'PEOPLE'} data.statisticsPointEnum
 * @returns
 */
export function focusMemberStatistics(data, shareData) {
  return request(`${serviceName}/focusMember`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/** @name 可分享的当前在线设备 */
export function getOnlineDevice(data, shareData) {
  return request(`${serviceName}/target/deviceList`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/** @name 可分享的实时在场人数明细列表 */
export function shareRealPresenceDetail(data, shareData) {
  return request(`${serviceName}/target/realPresenceDetail`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/** @name 可分享的平均在场人数明细列表 */
export function shareRealAvgDetail(data, shareData) {
  return request(`${serviceName}/target/realAvgDetail`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/** @name 可分享的设备事件明细列表 */
export function sharePageDetail(data, shareData) {
  return request(`${serviceName}/target/pageDetail`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/** @name 可分享的在场-离场明细 */
export function shareEntryOrLeaveDetails(data, shareData) {
  return request(`${serviceName}/entryOrLeaveDetails`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/** @name 电子围栏列表 */
export function getGeoFenceZoneList(data, shareData) {
  return request(`${serviceName}/geofence/zone/list`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/** @name 获取大屏设置数据 */
export function getScreenSetting(data) {
  return request(`${serviceName}/shareScreenSetting`, {
    method: 'GET',
    auth: false,
    body: data,
  });
}
