<template>
  <div>
    <div :class="$style.selectWrap">
      <div :class="$style.label">当前定位更新间隔</div>
      <a-select
        v-model="time"
        :placeholder="$t('iotScreenManage.pleaseSelect')"
      >
        <a-select-option
          v-for="item in timeList"
          :value="item.value"
          :key="item.value"
          >{{ item.label }}</a-select-option
        >
      </a-select>
    </div>
    <p :class="$style.tipText">
      注意：设置的间隔太短会明显影响设备续航，"实时"状态下每隔10秒更新1次定位
    </p>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { putFrequency } from '@/services/smart-hat/device-management';

@Component()
export default class PositionSetting extends Vue {
  @Prop({ type: Object }) detail;

  mounted() {
    this.time = this.detail.bootPositionFrequency;
  }
  time = 0;
  timeList = [
    {
      label: '实时',
      value: 0,
    },
    {
      label: '1' + this.$t('unit.m'),
      value: 1,
    },
    {
      label: '5' + this.$t('unit.m'),
      value: 5,
    },
    {
      label: '10' + this.$t('unit.m'),
      value: 10,
    },
    {
      label: '15' + this.$t('unit.m'),
      value: 15,
    },
    {
      label: '30' + this.$t('unit.m'),
      value: 30,
    },
    {
      label: '60' + this.$t('unit.m'),
      value: 60,
    },
    {
      label: '180' + this.$t('unit.m'),
      value: 180,
    },
    {
      label: '360' + this.$t('unit.m'),
      value: 360,
    },
    {
      label: '720' + this.$t('unit.m'),
      value: 720,
    },
    {
      label: '1440' + this.$t('unit.m'),
      value: 1440,
    },
  ];
  async getValue() {
    try {
      const data = {
        deviceId: this.detail.deviceId,
        frequency: 'BOOT_POSITION',
        size: this.time,
      };
      await putFrequency(data);
      await this.$message.success({
        content: this.$t('hat.deviceManagement.editModal.modified'),
        duration: 0.5,
      });
      return true;
    } catch {
      return false;
    }
  }
}
</script>

<style lang="less" module>
.selectWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  .label {
    margin-right: 10px;
  }
}
.tipText {
  color: #ee0707;
  margin-top: 10px;
  font-size: 13px;
}
</style>
