<template>
  <div :class="$style.container">
    <x-scroll-view
      :disabled="isEnd"
      :lowerThreshold="80"
      :immediateCheck="false"
      ref="Ref"
      @scrolltolower="loadMore"
    >
      <div :class="$style.header">
        <span :class="$style.one">姓名</span>
        <span :class="$style.two">项目</span>
        <span :class="$style.three">编组</span>
      </div>
      <div :class="$style.table" v-for="item in list" :key="item.deviceId">
        <a-checkbox v-model="item.checked"></a-checkbox>
        <div :class="$style.info">
          <span :class="$style.one">
            <span>{{ item.deviceName }}</span>
            <span v-if="item.userName">(</span>
            <x-oss-image
              :class="$style.img"
              :ossPath="item.userAvatar"
              v-if="item.userAvatar"
            />
            <span v-if="item.userName">{{ item.userName }})</span>
          </span>
          <span :class="$style.two">{{ item.projectName || '-' }}</span>
          <span :class="$style.three">{{ item.groupName || '-' }}</span>
        </div>
      </div>
    </x-scroll-view>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getDeviceList } from '@/services/smart-hat/device-management';
@Component()
export default class AddDevice extends Vue {
  mounted() {
    this.getData();
  }

  isEnd = false;
  async loadMore() {
    this.current += 1;
    await this.fetchData();
  }

  query = '';
  current = 1;
  pageSize = 10;
  async getData() {
    await this.fetchData();
  }
  @Prop() deviceIds;
  list = [];
  async fetchData() {
    const params = {
      current: this.current,
      size: this.pageSize,
      content: this.query,
      status: 'ONLINE',
    };
    const { records } = await getDeviceList(params);
    records.forEach(item => {
      const fItem = this.deviceIds.find(v => v === item.deviceId);
      if (fItem) {
        item.checked = true;
      } else {
        item.checked = false;
      }
    });
    this.list = [...this.list, ...records];
    this.isEnd = records.length < this.pageSize;
  }

  async getValue() {
    const result = this.list
      .filter(v => v.checked)
      .filter(v => !this.deviceIds.find(i => i === v.deviceId))
      .map(v => {
        return {
          deviceUserId: 'DEVICE_' + v.deviceName,
          deviceId: v.deviceId,
          deviceName: v.deviceName,
          avatar: v.userAvatar,
          userName: v.userName,
          status: '',
          enumMsg: '',
          isCall: false,
          resolution: 720,
          screenMode: 0,
          videoType: 'video',
          userType: 'DEVICE',
          muted: false,
          remoteVideoTrack: null,
          remoteAudioTrack: null,
          isFullScreen: false,
          lng: 0,
          lat: 0,
        };
      });
    return result;
  }
}
</script>
<style lang="less" module>
.container {
  height: 530px;
  :global {
    .ant-checkbox-wrapper {
      margin-left: 14px;
    }
  }
  .img {
    width: 20px;
    height: 20px;
    border-radius: 100%;
  }
  .header {
    display: flex;
    justify-content: space-around;
    height: 45px;
    background-color: var(--primary-10);
    .one {
      margin-left: 28px;
      flex: 1;
      text-align: center;
      line-height: 45px;
    }
    .two {
      flex-basis: 120px;
      text-align: center;
      line-height: 45px;
    }
    .three {
      flex-basis: 120px;
      text-align: center;
      line-height: 45px;
    }
  }
  .info {
    display: inline-flex;
    justify-content: space-around;
    height: 45px;
    width: calc(100% - 28px);
    .one {
      flex: 1;
      align-items: center;
      justify-content: center;
      display: flex;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .two {
      flex-basis: 120px;
      text-align: center;
      line-height: 45px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .three {
      flex-basis: 120px;
      text-align: center;
      line-height: 45px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .table:nth-child(2n) {
    background-color: var(--table-row);
  }
  .table:nth-child(2n-1) {
    background-color: var(--table-row-stripe);
  }
}
</style>
