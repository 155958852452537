var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:_vm.$style.content,style:({
    width: _vm.$store.state.home.bigScreen ? '100vw' : '100%',
    height: _vm.$store.state.home.bigScreen ? '100vh' : '100%',
    position: _vm.$store.state.home.bigScreen ? 'fixed' : '',
    top: '-0',
    left: 0,
    zIndex: 9999,
  })},[_c('section',{class:_vm.$style.timeWrap},[_c('span',[_vm._v("最近更新："),_c('span',{class:_vm.$style.time},[_vm._v(_vm._s(_vm.formatDate(_vm.lastStatusTime)))])])]),_c('div',{class:_vm.$style.wrap,style:({
      width: _vm.$store.state.home.bigScreen ? '100vw' : '100%',
      height: _vm.$store.state.home.bigScreen ? '100vh' : '100%',
    }),attrs:{"id":"container"}}),_c('section',{class:_vm.$style.selectWrap},[_c('span',{class:_vm.$style.label},[_vm._v("电子围栏")]),_c('a-select',{on:{"change":_vm.handleChange},model:{value:(_vm.geofence),callback:function ($$v) {_vm.geofence=$$v},expression:"geofence"}},_vm._l((_vm.list),function(item){return _c('a-select-option',{key:item.geofenceId,attrs:{"value":item.geofenceId}},[_vm._v(_vm._s(item.geofenceName))])}),1)],1),_c('div',{class:_vm.$style.iconBtn},[_c('div',{class:_vm.$style.icon,attrs:{"title":"关闭地图"},on:{"click":_vm.handleClose}},[_c('a-icon',{attrs:{"type":"close"}})],1)]),(_vm.detail.status === 'ONLINE')?_c('div',{class:_vm.$style.positionValue},[_c('div',{class:_vm.$style.circleBox},[_c('div',{class:_vm.$style.circle},[_c('div',{class:_vm.$style.value},[_c('span',{class:_vm.$style.num},[_vm._v(_vm._s(_vm.speed))]),_c('span',{class:_vm.$style.unit},[_vm._v("km/h")])]),_c('div',{class:_vm.$style.name},[_vm._v("速度")])]),_c('div',{class:_vm.$style.circle},[_c('div',{class:_vm.$style.value},[_c('span',{class:_vm.$style.num},[_vm._v(_vm._s(_vm.altitude))]),_c('span',{class:_vm.$style.unit},[_vm._v("m")])]),_c('div',{class:_vm.$style.name},[_vm._v("海拔")])])]),_c('div',{class:_vm.$style.updateTime,on:{"click":_vm.handleBinding}},[_c('a-icon',{class:_vm.$style.timeIcon,attrs:{"type":"history"}}),_vm._v(_vm._s(_vm.formatTime(_vm.updateTime))+"后更新 ")],1)]):_vm._e(),_c('div',{class:_vm.$style.toolBar},[_c('div',{class:_vm.$style.mapZoom},[_c('div',{class:_vm.$style.zoomBtn,attrs:{"title":"放大"},on:{"click":function($event){return _vm.setZoom('add')}}},[_c('a-icon',{attrs:{"type":"plus"}})],1),_c('div',{class:_vm.$style.zoomBtn,attrs:{"title":"缩小"},on:{"click":function($event){return _vm.setZoom('minus')}}},[_c('a-icon',{attrs:{"type":"minus"}})],1)]),_c('div',{class:_vm.$style.toolBtn,attrs:{"title":"地图缩放"}},[_c('x-icon',{class:_vm.$style.icon,attrs:{"type":_vm.$store.state.home.bigScreen
            ? 'tc-icon-fullscreen-exit'
            : 'tc-icon-fullscreen'},on:{"click":_vm.handleScreen}})],1),_c('div',{class:_vm.$style.toolBtn,attrs:{"title":"测距"},on:{"click":_vm.measureDistance}},[_c('i',{class:_vm.$style.icon,domProps:{"innerHTML":_vm._s(_vm.RuleIcon)}})]),_c('div',{class:_vm.$style.toolBtn,attrs:{"title":"刷新定位"},on:{"click":_vm.refreshLocation}},[_c('i',{class:_vm.$style.icon,domProps:{"innerHTML":_vm._s(_vm.PositionIcon)}})]),_c('div',{class:_vm.$style.toolBtn,attrs:{"title":"维度切换"},on:{"click":_vm.dimensionChange}},[(!_vm.is3D)?_c('i',{class:_vm.$style.icon,domProps:{"innerHTML":_vm._s(_vm.twoDimensions)}}):_c('i',{class:_vm.$style.icon,domProps:{"innerHTML":_vm._s(_vm.threeDimensions)}})])]),_c('div',{class:_vm.$style.mapType},[_c('div',{class:[_vm.$style.typeBox, _vm.mapType === '1' ? _vm.$style.selected : ''],on:{"click":function($event){return _vm.setMapType('1')}}},[_c('img',{attrs:{"src":require("@/assets/images/manage-model/satellite.png"),"alt":"地球","srcset":""}}),_c('span',{class:_vm.$style.title},[_vm._v("地球")])]),_c('div',{class:[_vm.$style.typeBox, _vm.mapType === '2' ? _vm.$style.selected : ''],on:{"click":function($event){return _vm.setMapType('2')}}},[_c('img',{attrs:{"src":require("@/assets/images/manage-model/administration.png"),"alt":"行政","srcset":""}}),_c('span',{class:_vm.$style.title},[_vm._v("行政")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }