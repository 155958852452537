<template>
  <div :class="$style.hint">
    <a-spin :spinning="spinning">
      <div>
        <a-icon type="exclamation-circle" style="margin-right: 10px" />
        <span style="font-weight: 600"
          >{{ $t('hat.managementModal.setting.hint.tips') }}：</span
        >
        <template v-if="haveShift">
          <span v-html="tipsOne"></span>
        </template>
        <template v-if="!isJoin">
          <span v-html="tipsTwo"></span>
        </template>
        <span v-if="noClock">
          {{
            this.$t('hat.managementModal.setting.hint.tipsThree', {
              value: deviceDetail.userName,
            })
          }}
          <!-- 当前佩戴成员"{{ deviceDetail.userName }}"今日无需打卡 -->
        </span>
      </div>
      <div v-if="haveShift">
        <div v-if="attendanceData.currentStatus == '1'">
          <span style="margin-left: 24px">本轮班次：</span>
          <span
            >{{ attendanceData.current }}（状态：{{
              syncStatus[attendanceData.currentSyncStatus]
            }}<a-icon
              :type="currentLoading ? 'loading' : 'sync'"
              v-if="attendanceData.currentSyncStatus != '1'"
              @click="
                synchronization(
                  attendanceData.currentShift,
                  'current',
                  attendanceData.currentSyncStatus,
                )
              "
              :class="$style.icon"
            />）</span
          >
        </div>
        <div v-if="attendanceData.nextStatus == '1'">
          <span style="margin-left: 24px">下轮班次：</span>
          <span
            >{{ attendanceData.next }}（状态：{{
              syncStatus[attendanceData.nextSyncStatus]
            }}<a-icon
              :type="nextLoading ? 'loading' : 'sync'"
              v-if="attendanceData.nextSyncStatus != '1'"
              @click="
                synchronization(
                  attendanceData.nextShift,
                  'next',
                  attendanceData.nextSyncStatus,
                )
              "
              :class="$style.icon"
            />）</span
          >
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { Component, Vue, InjectReactive } from 'vue-property-decorator';
import {
  getAttendanceById,
  synchronizeAttendance,
} from '@/services/smart-hat/attendance';

@Component()
export default class SettingHint extends Vue {
  @InjectReactive('deviceDetail') deviceDetail;

  mounted() {
    this.getAttendance();
  }
  get tipsOne() {
    const c = `<a href="${
      this.pluginUrl
    }" target="_blank" rel="noopener noreferrer">${this.$t(
      'hat.managementModal.setting.hint.component',
    )}</a>`;
    return `<span>${this.$t('hat.managementModal.setting.hint.tipsOne', {
      a: this.deviceDetail.userName,
      b: this.attendanceData.group,
      c,
    })}
      </span>`;
  }
  get tipsTwo() {
    const b = `<a href="${
      this.pluginUrl
    }" target="_blank" rel="noopener noreferrer">${this.$t(
      'hat.managementModal.setting.hint.component',
    )}</a>`;
    return `<span>${this.$t('hat.managementModal.setting.hint.tipsTwo', {
      a: this.deviceDetail.userName,
      b,
    })}
      </span>`;
  }
  get pluginUrl() {
    const url = {
      dev: `http://dev.app.${process.env.VUE_APP_HOST}/expand/gadget`,
      production: `https://app.${process.env.VUE_APP_HOST}/expand/gadget`,
      sit: `https://sit.app.${process.env.VUE_APP_HOST}/expand/gadget`,
      uat: `https://uat.app.${process.env.VUE_APP_HOST}/expand/gadget`,
    };
    return (
      url[process.env.VUE_APP_ENV] || 'https://app.sandieyun.com/expand/gadget'
    );
  }
  // 当前成员是否加入考勤组
  get isJoin() {
    return this.attendanceData.join == '1';
  }
  // 有班次
  get haveShift() {
    return (
      this.isJoin &&
      (this.attendanceData.currentStatus == '1' ||
        this.attendanceData.nextStatus == '1')
    );
  }
  // 已加入考勤组但今日无需打卡
  get noClock() {
    return (
      this.isJoin &&
      this.attendanceData.currentStatus != '1' &&
      this.attendanceData.nextStatus != '1'
    );
  }
  syncStatus = ['未同步', '已同步', '同步中', '同步失败'];
  attendanceData = {};
  spinning = false;
  async getAttendance() {
    this.spinning = true;
    try {
      this.attendanceData = await getAttendanceById(this.deviceDetail.userId);
      this.attendanceData.current = this.attendanceData.current.join(',');
      this.attendanceData.next = this.attendanceData.next.join(',');
    } finally {
      this.spinning = false;
    }
  }
  currentLoading = false;
  nextLoading = false;
  async synchronization(shift, type, status) {
    if (type == 'current') {
      this.currentLoading = true;
    }
    if (type == 'next') {
      this.nextLoading = true;
    }
    try {
      const data = {
        shift: shift,
        deviceId: this.deviceDetail.deviceId,
      };
      if (status != '2') {
        await synchronizeAttendance(data);
        this.$message.success('同步成功！');
      }
      this.getAttendance();
      this.currentLoading = this.nextLoading = false;
    } catch {
      this.currentLoading = this.nextLoading = false;
    }
  }
}
</script>

<style lang="less" module>
.hint {
  width: 98%;
  border: 1px solid var(--border);
  border-radius: 5px;
  padding: 10px;
  .icon {
    color: var(--primary);
    margin-left: 8px;
  }
}
</style>
