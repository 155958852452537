<template>
  <div style="width: 100%; height: 100%;">
    <div
      ref="barChartRef"
      style="width: 100%; height: 100%;"
      v-if="!isEmpty"
    ></div>
    <empty-content v-else />
  </div>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import { omittedText } from '@/views/hat/utils';
import _ from 'lodash';
import EmptyContent from '@/components/empty-content';

@Component({
  components: {
    EmptyContent,
  },
})
export default class BarChart extends Vue {
  @Prop({ type: Boolean, default: true }) isVertical;
  @Prop({ type: String, default: '' }) unit;
  @Prop({ type: Boolean, default: false }) showLabel;
  @Prop({
    type: Object,
    default: () => {},
  })
  chartData;

  async mounted() {
    this.initChart();
  }

  beforeDestroy() {
    window.removeEventListener('resize', () => {
      this.chartInstance.resize();
    });
    this.chartInstance && this.chartInstance.dispose();
  }

  isEmpty = false;
  @Watch('chartData', { deep: true, immediate: true })
  async chartDataChange(newVal) {
    this.isEmpty = newVal.total === 0;
    if (newVal && newVal.total) {
      await this.$nextTick();
      await this.initChart();
    } else {
      this.chartInstance?.dispose();
      this.chartInstance = null;
    }
  }

  get options() {
    return {
      color: ['#FF7345', '#6A6E88'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        itemWidth: 10,
        itemHeight: 9,
        align: 'left',
        right: '2%',
        top: '7%',
        // bottom: 0,
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      ...this.getAxisOption(),
      series: this.chartData?.dataList?.map(item => {
        return {
          name: item.tag,
          type: 'bar',
          stack: 'total',
          barMaxWidth: '20',
          label: {
            show: this.showLabel,
            position: 'outside',
            formatter: ({ value }) => {
              return value + this.unit;
            },
          },
          emphasis: {
            focus: 'series',
          },
          data: item.values.map(childItem => {
            return {
              value: childItem.value,
              deviceId: childItem.deviceId,
              deviceName: childItem.deviceName,
              time: childItem.time,
            };
          }),
        };
      }),
    };
  }

  getAxisOption() {
    if (this.isVertical) {
      return {
        xAxis: {
          type: 'value',
          axisLabel: {
            formatter: value => {
              return omittedText(value, 22) + this.unit;
            },
          },
        },
        yAxis: {
          type: 'category',
          axisLabel: {
            formatter: value => {
              return omittedText(value, 22);
            },
          },
          data: this.chartData?.nameList,
        },
        dataZoom: [
          {
            type: 'inside',
            orient: 'vertical',
            start:
              this.chartData?.nameList?.length > 15
                ? (1 - 15 / this.chartData?.nameList?.length) * 100
                : 0,
            end: 100,
          },
        ],
      };
    } else {
      return {
        xAxis: {
          type: 'category',
          axisLabel: {
            formatter: value => {
              return omittedText(value, 22);
            },
          },
          data: this.chartData?.nameList,
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: value => {
              return omittedText(value, 22) + this.unit;
            },
          },
        },
        dataZoom: [
          {
            type: 'inside',
            orient: 'horizontal',
            start: 0,
            end:
              this.chartData?.nameList?.length > 15
                ? (15 / this.chartData?.nameList?.length) * 100
                : 100,
          },
        ],
      };
    }
  }

  chartInstance = null;
  async initChart() {
    if (!this.chartInstance) {
      this.chartInstance = echarts.init(this.$refs.barChartRef);
    }
    this.chartInstance.setOption(this.options);
    this.chartInstance.off('click');
    this.chartInstance.on('click', params => {
      this.$emit('click-chart', params);
    });
    window.addEventListener(
      'resize',
      _.debounce(() => {
        this.chartInstance.resize();
      }, 1000),
    );
  }
}
</script>
