<template>
  <div :class="$style.map" id="positionMap"></div>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { funAMap } from '@/views/hat/electron-fence/components/util';
import { getDeviceDetail } from '@/services/smart-hat/device';
import { createModuleUrl, Emitter } from '@/utils';
import { delay } from '@triascloud/utils';
@Component()
export default class PositionMap extends Vue {
  @Prop({ type: String }) deviceId;

  @Watch('deviceId', { immediate: true })
  async init() {
    await this.getDeviceInfo();
    await this.initMap();
  }

  async mounted() {
    await this.getDeviceInfo();
    await this.initMap();
    Emitter.$on('POSITION', this.changePosition);
  }

  beforeDestroy() {
    Emitter.$off('POSITION', this.changePosition);
  }

  // 当socket定位更新时，执行此方法
  async changePosition(data) {
    const position = [data.lngLat.x, data.lngLat.y];
    if (position.length > 0) {
      // 设置点标记的动画效果，此处为弹跳效果
      this.centerMarker.moveTo(
        [this.center[0], Number(this.center[1]) + 0.0001],
        {
          duration: 500,
          delay: 100,
        },
      );
      await delay(1000);
      this.centerMarker.setPosition(position);
      this.positionRange.setCenter(position);
    }
  }

  center = undefined;
  deviceInfo = {};
  async getDeviceInfo() {
    const detail = await getDeviceDetail(this.deviceId);
    this.deviceInfo = detail;
    if (this.deviceInfo?.longitude) {
      this.center = [this.deviceInfo.longitude, this.deviceInfo.latitude];
    }
  }

  map = null;
  AMap = null;
  async initMap() {
    if (this.map) {
      this.map = null;
      this.AMap = null;
    }
    const AMap = await funAMap({
      plugins: ['AMap.MoveAnimation'],
    });
    this.AMap = AMap;
    const { mode } = this.$store.state.crossStorage.skin;
    this.map = new AMap.Map('positionMap', {
      zoom: 16,
      center: this.center || undefined,
      mapStyle: mode === 'dark' ? 'amap://styles/dark' : '',
    });
    const hatColor = {
      BLUE: '365',
      YELLOW: '366',
      RED: '367',
      WHITE: '368',
      ORANGE: '369',
    };
    let image;
    if (hatColor[this.deviceInfo.color]) {
      image = `${createModuleUrl('iot-max')}/static/hat/${
        hatColor[this.deviceInfo.color]
      }.png`;
    } else {
      image = `${createModuleUrl('iot-max')}/static/hat/365.png`;
    }
    const centerMarker = new this.AMap.Marker({
      position: this.center,
      icon: new this.AMap.Icon({
        imageSize: [36, 36],
        image,
      }),
      offset: [-18, -18],
    });
    this.centerMarker = centerMarker;
    this.addPositionRange();
    centerMarker.setMap(this.map);
  }

  /** 给定位加上区域范围 */
  positionRange = null;
  addPositionRange() {
    const POSITION_KEY = {
      BASE_STATION: 50,
      WIFI: 50,
      SATELLITE: 10,
    };
    this.positionRange = new this.AMap.Circle({
      center: this.center,
      radius: POSITION_KEY[this.deviceInfo.positionType], // 半径
      borderWeight: 3,
      strokeColor: '#1791fc',
      strokeWeight: 1,
      strokeOpacity: 0.5,
      fillOpacity: 0.4,
      strokeStyle: 'solid',
      fillColor: '#1791fc',
      zIndex: 50,
    });
    this.map.add(this.positionRange);
  }
}
</script>
<style lang="less" module>
.map {
  height: 100%;
  width: 100%;
}
</style>
