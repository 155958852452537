<template>
  <div :class="$style.main">
    <global-header @click.native="handleHideMenu">
      <template slot="left">
        <menu-drawer
          ref="menu"
          base-path="/production"
          :origin="origin"
          :key="tenantId"
          :auto-add-recent="false"
          :custom-url="createCustomUrl"
          @data-change="handleMenuChange"
          @menusShowStatusChange="handleMenuShowStatusChange"
        />
        <company-select
          :data="companyList"
          :value="tenantId"
          @change="handleCompanyChange"
          v-if="tenant"
        />
        <div
          v-else
          :class="$style.noCompany"
          @click="handleShowOperationSelect"
        >
          <x-icon type="tc-icon-company" :class="$style.noCompanyIcon" />
          <span :class="$style.noCompanyTip">加入 / 创建企业</span>
        </div>
      </template>
      <global-header-right :key="tenantId" slot="right" :feature="feature" />
    </global-header>
    <router-view :key="tenantId" :class="$style.content" />
  </div>
</template>
<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  Header as GlobalHeader,
  MenuDrawer,
  CompanySelect,
} from '@triascloud/x-blocks';
import GlobalHeaderRight from './components/global-header-right';
import { crossStorageModule, globalModule } from '@/enum/store';
import { switchEnterprise } from '@/services/tenant-center/enterprise';
import { createModuleUrl } from '@/utils';
import { XOperationSelect } from '@/components/x-operation-select';
import { findTree } from '@triascloud/utils';
@Component({
  components: {
    GlobalHeader,
    GlobalHeaderRight,
    MenuDrawer,
    CompanySelect,
  },
})
export default class MainLayout extends Vue {
  @globalModule.State companyList;
  @globalModule.State refreshMenuFlag;
  @crossStorageModule.State tenant;
  @crossStorageModule.Mutation setItem;
  @globalModule.Action refreshCompanyList;
  @globalModule.Action refreshMenu;
  @globalModule.Action refreshUser;
  @globalModule.Mutation updateMenusShowStatus;
  @globalModule.State menusShowStatus;
  @globalModule.State isBuildEnterprise;
  @globalModule.Mutation updateIsBuildEnterprise;
  get feature() {
    const Feature = GlobalHeaderRight.Feature;
    return [
      Feature.Home,
      Feature.Application,
      /**  @todo Release Adjustment */
      Feature.Data,
      Feature.Task,
      Feature.Todo,
      Feature.Message,
      Feature.Download,
      Feature.Theme,
      Feature.Language,
      Feature.Avatar,
      Feature.Setting,
      Feature.Expand,
    ];
  }
  get origin() {
    return createModuleUrl('iot-max');
  }
  get tenantId() {
    return this.tenant ? this.tenant.pkId : 'VISITOR';
  }
  handleMenuChange({ collection }) {
    this.$store.commit('home/updateCollection', collection);
  }
  async handleShowOperationSelect() {
    await XOperationSelect.create();
    this.refreshCompanyList();
  }
  handleMenuShowStatusChange(status) {
    this.updateMenusShowStatus(status);
  }
  async handleCompanyChange(company) {
    await switchEnterprise(company.pkId);
    // 刷新全局权限
    this.$router.permission.init(true);
    await this.refreshUser();
    this.setItem({
      key: 'tenant',
      value: JSON.stringify(company),
    });
    const path = await this.$router.resolvePermission(this.$route.path);
    this.$router.replace(path);
  }
  @Watch('refreshMenuFlag')
  handleRefreshMenu(flag) {
    if (!flag) return;
    this.$refs.menu.loadMenu();
  }
  @Watch('$route.path')
  handleRoutePathChange() {
    if (!this.$refs.menu) return;
    const fullPath = createModuleUrl('app') + this.$route.path;
    const menu = findTree(
      this.$refs.menu.menuList,
      menu => menu.uri && fullPath.startsWith(menu.uri),
      { children: 'childMenu' },
    );
    if (!menu) return;
    this.$refs.menu.addRecentMenu(menu);
  }

  createCustomUrl(menu) {
    if (menu.moduleId && menu.menuId) {
      return `${createModuleUrl(
        menu.moduleId === 'iot' ? 'iot-max' : menu.moduleId,
      )}${menu.menuId}`;
    } else if (menu.menuId) {
      return `${createModuleUrl('app')}/form/${menu.menuId}`;
    } else {
      return menu.uri;
    }
  }

  handleHideMenu() {
    if (this.$refs.menu.menusDrawerVisible) {
      this.$refs.menu.handleMenusDrawerClose();
    }
  }

  mounted() {
    this.handleRoutePathChange();
  }
}
</script>
<style lang="less" module>
.content {
  height: calc(100% - 56px);
  overflow-x: hidden;
  overflow-y: auto;
}
.noCompany {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.noCompanyIcon {
  font-size: 24px;
  margin-right: 10px;
  color: white;
  padding: 6px;
  background-color: var(--primary);
  border-radius: 50%;
}
.noCompanyTip {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: var(--font);
}
</style>
