<template>
  <a-form-model
    layout="horizontal"
    :model="form"
    ref="form"
    :rules="rules"
    :class="$style.mode1Wrap"
  >
    <a-form-model-item prop="videoMode" label="巡查模式">
      <a-radio-group v-model="form.videoMode">
        <a-radio :value="VIDEO_MODE.SENSELESS_MONITORING">无提醒</a-radio>
        <a-radio :value="VIDEO_MODE.SENSORY_MONITORING">有限提醒</a-radio>
      </a-radio-group>
    </a-form-model-item>
    <div
      :class="$style.mode1Tip"
      v-if="form.videoMode === VIDEO_MODE.SENSORY_MONITORING"
    >
      自动开启摄像头和麦克风，开始和结束时有语音提示
    </div>
    <div
      :class="$style.mode1Tip"
      v-if="form.videoMode === VIDEO_MODE.SENSELESS_MONITORING"
    >
      自动开启设备摄像头和麦克风，期间无任何提示声
    </div>
    <p>{{ $t('hat.talk.tip.attention') }}</p>
    <p>1、您的麦克风会默认关闭，开启后设备端可正常收听到您的语音；</p>
    <p>2、请确保设备所处场允许拍摄，且不侵犯现场人员的隐私；</p>
    <p>3、巡查模式需最新设备固件支持，否则将按正常通话处理；</p>
    <p>4、请确保使用场景的合规、合法性，三叠不对由此行为承担任何责任；</p>
  </a-form-model>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { VIDEO_MODE } from './person-info.vue';
@Component()
export default class MonitorConfirm extends Vue {
  VIDEO_MODE = VIDEO_MODE;

  form = {
    videoMode: '',
  };

  rules = {
    videoMode: [
      {
        required: true,
        message: '请选择巡查模式',
        trigger: 'change',
      },
    ],
  };

  async getValue() {
    await this.$refs.form.validate();
    return this.form.videoMode;
  }
}
</script>
<style lang="less" module>
.mode1Wrap {
  font-size: 14px;
  color: var(--font);
  :global {
    .ant-form-item-control-wrapper {
      display: inline-block;
      margin-left: 10px;
    }
  }
  p {
    font-size: 12px;
    color: var(--font-sub);
  }
  .mode1Tip {
    font-size: 12px;
    margin-left: 76px;
    color: var(--font-sub);
  }
  .mode1Top {
    margin-bottom: 4px;
  }
  .mode1Title {
    font-size: 14px;
    margin-right: 10px;
  }
  .mb {
    font-size: 16px;
    margin-right: 10px;
    margin-bottom: 12px;
  }
}
</style>
