<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-form-model-item label="文件备注名称" prop="mark">
      <a-input
        v-model="form.mark"
        placeholder="请输入备注名称，限1000字符长度"
        :maxLength="1000"
      ></a-input>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component()
export default class FileRemark extends Vue {
  @Prop({ type: Object, default: () => {} }) data;

  mounted() {
    this.initData();
  }
  form = {
    fileId: '',
    mark: '',
  };
  rules = {
    mark: [
      {
        required: true,
        message: '文件备注名称不能为空',
      },
    ],
  };
  async initData() {
    this.form.fileId = this.data.fileId;
    this.form.mark = this.data.mark;
  }
  async getValue() {
    await this.$refs.form.validate();
    return this.form;
  }
}
</script>

<style lang="less" module></style>
